import React, { useState, useCallback, useMemo } from "react";
import { Card, CardBody, Collapse } from "reactstrap";

import { components } from "@crewos/shared";

const { Icon } = components;

const SAFETY_STATUS = {
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  DELETED: "Deleted",
};

const TemplatesWorkOrder = ({ children, templateWorkOrder }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleCollapse = useCallback(
    ($event) => {
      $event.stopPropagation();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  const groupByStatus = useMemo(() => {
    return templateWorkOrder.safetys.reduce((acc, safety) => {
      if (!acc[safety.status]) {
        acc[safety.status] = [];
      }
      acc[safety.status].push(safety);
      return acc;
    }, {});
  }, [templateWorkOrder]);

  const textByStatus = useMemo(() => {
    return Object.keys(groupByStatus)
      .map((status) => {
        return `${groupByStatus[status].length} ${SAFETY_STATUS[status]}`;
      })
      .join(", ");
  }, [groupByStatus]);

  return (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody>
        <div
          onClick={handleCollapse}
          className="cursor-pointer p-2 d-flex justify-content-between align-items-center row"
        >
          <div className="d-flex align-items-center flex-grow-1 col-8">
            <h3 className="text-truncate w-auto mb-0">
              {templateWorkOrder.name} - {templateWorkOrder.template.name}
            </h3>
          </div>
          <div className="d-flex align-items-center flex-shrink-0 text-end col-4 justify-content-end">
            <small className="me-4 text-muted">{textByStatus}</small>
            <small className="text-primary" data-testid="collapse-text">
              View {isOpen ? "less" : "more"}
            </small>
            <Icon
              name="chevron-right"
              className={`mx-2 rotate ${isOpen ? "rotate-90" : ""}`}
            />
          </div>
        </div>
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </CardBody>
    </Card>
  );
};

export default TemplatesWorkOrder;
