import React, { useEffect, useState } from "react";

import { DebounceInput } from "react-debounce-input";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useGetPayroll } from "../api/Reports.hooks";
import PayrollDateDetail from "../components/PayrollDateDetail";

import moment from "moment";

const { useAuth } = data;

const dateFormat = "MM/DD";

const TRAVEL_TIMES_PACKAGE = "traveltimes";
const WORK_TIMES_PACKAGE = "worktimes";

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";
const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const { AdvanceTable, WeekSelector, AdvanceTablePagination, Icon } = components;

const getDateLabelWithIncrement = (date, increment = 0) => {
  const incrementDate = moment(date).add(increment, "day");
  return incrementDate.format("YYYY-MM-DD");
};

const PayrollReport = () => {
  const [authContext] = useAuth();
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [refresh, setRefresh] = useState();
  const [dateDetailsModal, setDateDetailsModal] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [weekStart, setWeekStart] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );

  const { data: reportData, isLoading, get: getPayroll } = useGetPayroll();

  useEffect(() => {
    getPayroll({
      search,
      weekStart,
      page,
      pageSize: sizePerPage,
    });
  }, [getPayroll, refresh, search, weekStart, page, sizePerPage]);

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData, setData]);

  const onSeeDateDetails = (date, employeeId, employee, isTotal) =>
    setDateDetailsModal({ date, employeeId, employee, isTotal });

  const startEndTimeSettingEnabled =
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      TRAVEL_TIMES_PACKAGE,
      START_END_TRAVEL_TIME_SETTING
    ) ||
    sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      WORK_TIMES_PACKAGE,
      START_END_WORK_TIME_SETTING
    );

  const weekDays = Array.from(Array(7).keys()).map((c) => {
    const date = getDateLabelWithIncrement(weekStart, c);
    return {
      accessor: `${date}.hours`,
      header: `${moment(date).format("dd")} ${moment(date).format(dateFormat)}`,
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      type: "number",
      Cell: (rowData) => {
        if (!rowData.row[date]) {
          return <span data-value={0}>0</span>;
        }

        const { hours, rawTimes } = rowData.row[date];

        const hasInProgressTime = rawTimes.find(
          (time) => time.startTime && !time.endTime
        );

        if (rowData.row.isExport) {
          return hasInProgressTime ? "In Progress" : hours.toFixed(2);
        }

        const isLink = hasInProgressTime || rawTimes.length;

        const value = hasInProgressTime
          ? "In Progress"
          : Math.max(hours, 0).toFixed(2);

        return (
          <span
            onClick={() =>
              isLink
                ? onSeeDateDetails(
                    date,
                    rowData.row.employeeId,
                    rowData.row.employee
                  )
                : null
            }
            data-value={value}
            className={isLink ? "text-link" : ""}
          >
            {value}
          </span>
        );
      },
    };
  });

  const columns = [
    {
      accessor: "employee",
      header: "Employee",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => {
        const { employee } = rowData.row;
        return employee;
      },
    },
    ...weekDays,
    {
      accessor: "hours",
      header: "Total Hours",
      headerProps: { className: "text-truncate" },
      cellProps: {
        className: "text-truncate",
      },
      type: "number",
      Cell: (rowData) => {
        const isInProgress =
          startEndTimeSettingEnabled &&
          Object.values(rowData.row)
            .flatMap((entry) => entry.rawTimes)
            .filter(Boolean)
            .find((time) => time.startTime && !time.endTime);

        if (isInProgress) {
          return (
            <span
              onClick={() =>
                onSeeDateDetails(
                  weekStart,
                  rowData.row.employeeId,
                  rowData.row.employee,
                  true
                )
              }
              className={"text-link"}
              data-value={"In Progress"}
            >
              In Progress
            </span>
          );
        }

        const { hours = 0 } = rowData.row;

        const rawTimes = Object.values(rowData.row).flatMap(
          (value) => value.rawTimes || []
        );

        if (!rawTimes.length) {
          return <span data-value={(0).toFixed(2)}>{(0).toFixed(2)}</span>;
        }

        if (rowData.row.isExport) {
          return hours.toFixed(2);
        }

        return (
          <span
            onClick={() =>
              onSeeDateDetails(
                weekStart,
                rowData.row.employeeId,
                rowData.row.employee,
                true
              )
            }
            className={"text-link"}
            data-value={Math.max(hours, 0).toFixed(2)}
          >
            {Math.max(hours, 0).toFixed(2)}
          </span>
        );
      },
    },
  ];

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Payroll Report</h2>
            <small className="text-muted ms-2 pt-1">({data.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-end col-6">
            <WeekSelector
              className="me-3"
              loading={isLoading}
              weekStart={weekStart}
              setWeekStart={setWeekStart}
            />
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search something"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              key={weekStart}
              exportable
              exportName="PayrollReport.csv"
              columns={columns}
              data={data.data || []}
              pageSize={sizePerPage}
              isLoading={isLoading}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                className: "overflow-hidden w-100",
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={data.count}
            pageCount={data.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {dateDetailsModal ? (
        <PayrollDateDetail
          employee={dateDetailsModal.employee}
          employeeId={dateDetailsModal.employeeId}
          date={dateDetailsModal.date}
          isTotal={dateDetailsModal.isTotal}
          onClose={(touched) => {
            setDateDetailsModal();
            if (touched) {
              setRefresh(!refresh);
            }
          }}
        />
      ) : null}
    </Container>
  );
};

export default PayrollReport;
