import React, { useEffect, useState } from "react";

import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { syncApi } from "../api/syncServices";

import {
  components,
  data,
  sharedHelper,
  toastConfig,
  api,
} from "@crewos/shared";

const { useUpdateSetting } = api;

const { useAuth, AUTH_ACTIONS } = data;

const { Icon, ConfirmationModal, UploadPhotosModal, TooltipItem, Loader } =
  components;

const BRANDING_LOGO = "BRANDING_LOGO";
const LOGIN_LOGO = "LOGIN_LOGO";

const isTest = typeof jest !== "undefined";

const SettingsHeaderActions = ({ settings }) => {
  const [, setAuthContext] = useAuth();
  const [confirmationModal, setConfirmationModal] = useState();
  const [updateSidebarLogoModal, setUpdateSidebarLogoModal] = useState();
  const [updateLoginLogoModal, setUpdateLoginLogoModal] = useState();

  const onSync = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        toast.promise(
          async () => {
            try {
              const res = await syncApi.doSync();
              sharedHelper.successToast(
                `Success! Imported ${res.workOrders} work orders and ${res.customers} customers.`
              );
            } catch (error) {
              sharedHelper.errorToast(`There was an error with your request.`);
            }
          },
          {
            pending: "Loading...",
          },
          toastConfig
        );
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Ben Parker Sync",
      body: `<span class="text-center">
          This will trigger a synchronization of Work Orders, & Customers data, new data will be added. Do you want to continue?
        </span>`,
      confirmColor: "warning",
    });
  };

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSetting,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      if (!isTest) {
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      }
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData, setAuthContext]);

  const onUploadLogo = (url, settingName) => {
    const setting = settings.find((setting) => setting.name === settingName);
    if (!setting) {
      return sharedHelper.errorToast("Setting not found");
    }
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSetting({
          ...setting,
          value: url,
        });
        setUpdateSidebarLogoModal();
        setConfirmationModal();
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Update logo",
      body: `Are you sure you want to change the logo?`,
      confirmText: "Update",
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      {isLoadingUpdateSetting ? <Loader size="sm" /> : null}
      <Button
        size="sm"
        className="me-3 d-flex align-items-center"
        color="primary"
        onClick={() => setUpdateSidebarLogoModal(true)}
      >
        <Icon name="upload" />
        <span className="ms-2">Sidebar/PDF Logo</span>
      </Button>
      <Button
        size="sm"
        className="me-3 d-flex align-items-center"
        color="primary"
        onClick={() => setUpdateLoginLogoModal(true)}
      >
        <Icon name="upload" />
        <span className="ms-2">Login Logo</span>
      </Button>
      <TooltipItem
        id="sync-tooltip"
        title="Import BR BP work orders and customers"
      >
        <Button
          size="sm"
          className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
          color="white"
          onClick={onSync}
        >
          <Icon name="download-cloud" />
        </Button>
      </TooltipItem>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : updateSidebarLogoModal ? (
        <UploadPhotosModal
          title={"Upload PDF & Sidebar Logo"}
          onClose={() => setUpdateSidebarLogoModal()}
          onSubmit={(data) => onUploadLogo(data.attachmentUrl, BRANDING_LOGO)}
        />
      ) : updateLoginLogoModal ? (
        <UploadPhotosModal
          title={"Upload Login Logo"}
          onClose={() => setUpdateLoginLogoModal()}
          onSubmit={(data) => onUploadLogo(data.attachmentUrl, LOGIN_LOGO)}
        />
      ) : null}
    </div>
  );
};
export default SettingsHeaderActions;
