import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";

import { DebounceInput } from "react-debounce-input";

import moment from "moment";

import { components, data, sharedHelper } from "@crewos/shared";

import { useDeleteUser, useGetUsers } from "../api/Users.hooks";
import UserModal from "../components/UserModal";

const { useAuth } = data;

const {
  Icon,
  Select,
  AdvanceTable,
  AdvanceTablePagination,
  ConfirmationModal,
  InformationModal,
} = components;

const columns = (onEdit, onDelete, onLastActivity) => [
  {
    accessor: "firstName",
    header: "First Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { firstName } = rowData.row;
      return firstName || "-";
    },
  },
  {
    accessor: "lastName",
    header: "Last Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { lastName } = rowData.row;
      return lastName || "-";
    },
  },
  {
    accessor: "email",
    header: "Email",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { email } = rowData.row;
      return email || "-";
    },
  },
  {
    accessor: "role.name",
    header: "Role",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { role } = rowData.row;
      return role.name || "-";
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { style: { width: 300 } },
    cellProps: {
      style: { width: 300 },
      className: "text-end",
    },
    disableSortBy: true,
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        {rowData.row.isInternal ? (
          <Badge pill size="sm" color="dark">
            Internal
          </Badge>
        ) : null}
        <Button
          size="sm"
          color="none"
          className="text-primary"
          onClick={() => onLastActivity(rowData.row)}
        >
          Activity
        </Button>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        {onDelete ? (
          <Button
            size="sm"
            color="none"
            className="text-danger"
            data-testid="delete-button"
            onClick={() => onDelete(rowData.row)}
          >
            Delete
          </Button>
        ) : null}
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const Users = () => {
  const [authContext] = useAuth();
  const [users, setUsers] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("firstName");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState();
  const [informationModal, setInformationModal] = useState();

  const {
    data: usersData,
    isLoading: isLoadingUsers,
    get: getUsers,
  } = useGetUsers();

  const { data: deleteUserData, deleteUser } = useDeleteUser();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getUsers({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getUsers,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (usersData) {
      setUsers(usersData);
    }
  }, [usersData, setUsers]);

  useEffect(() => {
    if (deleteUserData) {
      sharedHelper.successToast(`User deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteUserData, setRefresh]);

  const onEdit = (user) => setEditModal(user.id);

  const onDelete = (user) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteUser(user.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete User",
      body: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onLastActivity = (user) => {
    let appVersion = user.appVersion || "";
    let isWeb = appVersion.indexOf("WEB") > -1;
    if (isWeb) {
      appVersion = appVersion.replace(" - Not Set", "");
    }
    setInformationModal({
      isOpen: true,
      title: "User Activity",
      rawBody: true,
      bodyClass: "pb-4",

      onClose: () => setInformationModal(),
      body: (
        <ListGroup className="small">
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            <span>Platform</span>
            {!isWeb ? <span>Version</span> : null}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2"
            tag="div"
          >
            <span>{isWeb ? "Web" : "Mobile"}</span>
            <i className="small">{appVersion || "-"}</i>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            <span className="text-start">Activity</span>
            <span className="text-end">At</span>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-start py-2"
            tag="div"
          >
            {user.lastActivity ? (
              <>
                <i
                  className="text-start col-8"
                  style={{ overflowWrap: "break-word" }}
                >
                  {user.lastActivity}
                </i>
                <div className="text-end col-4">
                  {sharedHelper.formatDateTime(user.lastActivityTime)}
                </div>
              </>
            ) : (
              <span>No Activity</span>
            )}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-center align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            Sessions
          </ListGroupItem>
          <ListGroupItem
            className="d-flex flex-column justify-content-between align-items-start py-2"
            tag="div"
          >
            {user.authenticationTokens.length ? (
              <Table
                className="col-12 px-0 mb-0 overflow-hidden my-1"
                striped
                size="small"
              >
                <thead>
                  <tr className="bg-graylight small">
                    <th className="text-start">Platform</th>
                    <th className="text-center">Creation</th>
                    <th className="text-center">Expiration</th>
                    <th className="text-end">Is Active</th>
                  </tr>
                </thead>
                <tbody className="small">
                  {user.authenticationTokens
                    .map((token) => ({
                      ...token,
                      isActive: !moment(token.expiresAt).isSameOrBefore(
                        moment()
                      ),
                    }))
                    .sort((a, b) => {
                      if (a.isActive && !b.isActive) return -1;
                      if (!a.isActive && b.isActive) return 1;
                      return (
                        moment(b.createdAt).valueOf() -
                        moment(a.createdAt).valueOf()
                      );
                    })
                    .map((token) => (
                      <tr key={token.id}>
                        <td className="text-start">{token.platform}</td>
                        <td className="text-center">
                          {moment(token.createdAt).format(
                            "YYYY-MM-DD hh:mm:ss a"
                          )}
                        </td>
                        <td className="text-center">
                          {moment(token.expiresAt).format(
                            "YYYY-MM-DD hh:mm:ss a"
                          )}
                        </td>
                        <td className="text-end">
                          {token.isActive ? "Yes" : "No"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <span>No Sessions</span>
            )}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-center align-items-center py-2 bg-graylight fw-bold"
            tag="div"
          >
            Active Periods
          </ListGroupItem>
          <ListGroupItem
            className="d-flex flex-column justify-content-between align-items-start py-2"
            tag="div"
          >
            {user.activePeriods.length ? (
              <Table
                className="col-12 px-0 mb-0 overflow-hidden my-1"
                striped
                size="small"
              >
                <thead>
                  <tr className="bg-graylight small">
                    <th className="text-start">Period</th>
                    <th className="text-center">Start</th>
                    <th className="text-end">End</th>
                  </tr>
                </thead>
                <tbody className="small">
                  {user.activePeriods
                    .sort((x, y) =>
                      moment(y.start).isBefore(moment(x.start) ? 1 : -1)
                    )
                    .map((period, index) => (
                      <tr key={index}>
                        <td className="text-start">#{index + 1}</td>
                        <td className="text-center">
                          {moment(period.start).format("YYYY/MM/DD")}
                        </td>
                        <td className="text-end">
                          {period.end
                            ? moment(period.end).format("YYYY/MM/DD")
                            : "-"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : (
              <span>No periods</span>
            )}
          </ListGroupItem>
        </ListGroup>
      ),
    });
  };

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const activeSelect = useMemo(() => {
    return [
      { value: "ACTIVE", label: "Active" },
      { value: "INACTIVE", label: "Inactive" },
    ];
  }, []);

  const defaultActive = useMemo(() => {
    return activeSelect.find((option) =>
      showInactive ? option.value === "INACTIVE" : option.value === "ACTIVE"
    );
  }, [showInactive, activeSelect]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Users</h2>
            <small className="text-muted ms-2 pt-1">({users.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <Select
                id="activeSelect"
                name="activeSelect"
                onChange={(selected) =>
                  setShowInactive(selected.value === "INACTIVE")
                }
                value={defaultActive}
                options={activeSelect}
                placeholder="Filter by status"
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search users"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              exportable
              exportName="Users.csv"
              columns={columns(
                onEdit,
                isSuperAdmin ? onDelete : false,
                onLastActivity
              )}
              data={users.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingUsers}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={users.count}
            pageCount={users.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {editModal ? (
        <UserModal
          id={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <UserModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal ? (
        <InformationModal {...informationModal} />
      ) : null}
    </Container>
  );
};

export default Users;
