import { helpers, components } from "@crewos/shared";

import InspectionWrapper from "./components/InspectionWrapper";
import {
  CreateTemplates,
  EditTemplates,
  ListTemplates,
  ViewTemplate,
} from "./pages";

const { createRoutes } = helpers;

const { Settings } = components;

const routes = [
  {
    name: "Inspections",
    icon: "clipboard",
    sidebar: true,
    path: "/inspections",
    package: "inspections",
    component: InspectionWrapper,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    entries: [
      {
        icon: null,
        path: "templates/create",
        name: "New Template",
        component: CreateTemplates,
        sidebar: true,
      },
      {
        icon: null,
        path: "templates/edit/:id",
        name: "Edit Template",
        component: EditTemplates,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates/view/:id",
        name: "View Template",
        component: ViewTemplate,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates",
        name: "Templates",
        component: ListTemplates,
        sidebar: true,
      },
    ],
  },
  {
    sidebar: true,
    icon: null,
    name: "Inspections Settings",
    path: `inspections`,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "inspections",
    category: "Inspections",
    component: Settings,
    order: 1,
  },
];

const Routes = createRoutes(routes);

export { Routes, routes };
