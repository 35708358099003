// DynamicAttributesModal.js
import React, { useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import Loader from "./../Loader";

import AttributeFormModal from "./AttributeFormModal";

const DynamicAttributesModal = ({
  setting,
  dynamicAttributes,
  onSubmit,
  onClose,
}) => {
  const [localDynamicAttributes, setLocalDynamicAttributes] = useState(
    dynamicAttributes || []
  );
  const [loading, setLoading] = useState(false);

  const [editModalOpen, setEditModalOpen] = useState(false);

  const doSubmit = () => {
    setLoading(true);
    onSubmit(setting, localDynamicAttributes);
  };

  const openAddModal = () => {
    setEditModalOpen({});
  };

  const openEditModal = (attr) => {
    setEditModalOpen(attr);
  };

  const closeEditModal = () => {
    setEditModalOpen();
  };

  const handleSaveAttribute = (attribute) => {
    const index = localDynamicAttributes.findIndex(
      (attr) => attr.id === attribute.id
    );
    if (index < 0) {
      setLocalDynamicAttributes([...localDynamicAttributes, attribute]);
    } else {
      const dynamicAttributes = [...localDynamicAttributes];
      dynamicAttributes.splice(index, 1, attribute);
      setLocalDynamicAttributes(dynamicAttributes);
    }
    closeEditModal();
  };

  const handleDeleteAttribute = (attrId) => {
    const filtered = localDynamicAttributes.filter(
      (attr) => attr.id !== attrId
    );
    setLocalDynamicAttributes(filtered);
  };

  return editModalOpen ? (
    <AttributeFormModal
      attribute={editModalOpen}
      onClose={closeEditModal}
      onSubmit={handleSaveAttribute}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Dynamic Attributes
      </ModalHeader>
      <ModalBody className="text-center d-flex flex-wrap">
        {loading ? (
          <Loader size="sm" />
        ) : localDynamicAttributes.length ? (
          localDynamicAttributes.map((dynamicAttribute) => {
            const name = dynamicAttribute.label;
            return (
              <div className="col-4 px-2" key={dynamicAttribute.id}>
                <Card>
                  <CardHeader>
                    <div className="d-flex align-items-center justify-content-between">
                      {name}
                      <div className="d-flex align-items-center">
                        <Button
                          color="primary"
                          size="sm"
                          className="mr-2"
                          data-testid="edit-button"
                          onClick={() => openEditModal(dynamicAttribute)}
                        >
                          Edit
                        </Button>
                        <Button
                          className="ms-2"
                          color="danger"
                          size="sm"
                          data-testid="delete-button"
                          onClick={() =>
                            handleDeleteAttribute(dynamicAttribute.id)
                          }
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody
                    style={{ whiteSpace: "pre-wrap" }}
                    className="text-start"
                  >
                    <code>{JSON.stringify(dynamicAttribute, null, 2)}</code>
                  </CardBody>
                </Card>
              </div>
            );
          })
        ) : (
          <div className="text-center text-muted w-100">
            No attributes added
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div className="d-flex">
          <Button
            data-testid="discard-button"
            color="transparent"
            onClick={onClose}
            className="text-dark"
          >
            Discard
          </Button>
          <Button
            data-testid="add-button"
            className="ms-2"
            color="secondary"
            onClick={openAddModal}
          >
            Add Attribute
          </Button>
        </div>
        <Button
          data-testid="confirm-button"
          disabled={loading}
          color="primary"
          onClick={doSubmit}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DynamicAttributesModal;
