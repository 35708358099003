import React from "react";

import moment from "moment";

import Button from "reactstrap/lib/Button";

import { sharedHelper } from "../helpers/sharedHelper";
import Icon from "./Icon";

const WeekSelector = ({
  loading,
  weekStart,
  setWeekStart,
  weeksPeriod = 1,
  className,
  color = "primary",
  arrowColor = "5",
}) => {
  const previousWeek = () =>
    loading
      ? false
      : setWeekStart(
          moment(weekStart)
            .add(weeksPeriod * -1, "week")
            .format("YYYY-MM-DD")
        );

  const nextWeek = () =>
    loading
      ? false
      : setWeekStart(
          moment(weekStart).add(weeksPeriod, "week").format("YYYY-MM-DD")
        );

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <Button
        color={color}
        className={`text-${arrowColor}`}
        onClick={previousWeek}
        data-testid="prev-week-button"
      >
        <Icon name="chevron-left" />
      </Button>
      <span
        className="px-2 text-center flex-shrink-0 text-muted"
        style={{ minWidth: 140 }}
        dangerouslySetInnerHTML={{
          __html: sharedHelper.formatDateRange(
            weekStart,
            moment(weekStart).endOf("isoWeek").format("YYYY-MM-DD")
          ),
        }}
      />
      <Button
        color={color}
        className={`text-${arrowColor}`}
        onClick={nextWeek}
        data-testid="next-week-button"
      >
        <Icon name="chevron-right" />
      </Button>
    </div>
  );
};

export default WeekSelector;
