import React, { useState, useEffect } from "react";

import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import { components } from "@crewos/shared";

import { useUpdateLocationCustomerContacts } from "../api/Customers.hooks";
import NewCustomerLocationContactsModal from "./NewCustomerLocationContactModal";

const { Loader, Icon } = components;

const CustomerLocationContactsModal = ({
  customerLocation,
  onClose,
  onSubmit,
}) => {
  const [newContactModal, setNewContactModal] = useState();
  const [customerContacts, setCustomerContacts] = useState(
    customerLocation.contacts
  );

  const {
    isLoading: isLoadingUpdateCustomerContacts,
    update: updateLocationCustomerContacts,
    data: updateLocationCustomerContactsData,
  } = useUpdateLocationCustomerContacts();

  const doSubmit = async () => {
    updateLocationCustomerContacts({
      id: customerLocation.id,
      customerContacts,
    });
  };

  useEffect(() => {
    if (updateLocationCustomerContactsData) {
      onSubmit();
    }
  }, [updateLocationCustomerContactsData, onSubmit]);

  const onDelete = (index) => {
    let newCustomerContacts = [...customerContacts];
    newCustomerContacts.splice(index, 1);
    setCustomerContacts(newCustomerContacts);
  };

  return newContactModal ? (
    <NewCustomerLocationContactsModal
      onSubmit={(newContact) => {
        setCustomerContacts([
          ...customerContacts,
          { customerLocationId: customerLocation.id, ...newContact },
        ]);
        setNewContactModal();
      }}
      onClose={() => setNewContactModal()}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Contacts for Location
      </ModalHeader>
      <ModalBody className="text-center">
        <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
          <thead>
            <tr className="bg-graylight small text-muted">
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              {customerContacts.length ? (
                <th className="min-width-50"></th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {customerContacts.map((customerContact, index) => (
              <tr key={index}>
                <td>{customerContact.name}</td>
                <td>{customerContact.email}</td>
                <td>{customerContact.phone}</td>
                <td>
                  <div
                    onClick={() => onDelete(index)}
                    className="cursor-pointer"
                  >
                    <Icon name="trash" className="text-danger" />
                  </div>
                </td>
              </tr>
            ))}
            {!customerContacts.length ? (
              <tr>
                <td colSpan="4" className="text-muted small">
                  No customer contacts
                </td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <div className="d-flex align-items-center">
          <Button color="white" onClick={onClose}>
            Discard
          </Button>
          <Button
            className="ms-2"
            color="secondary"
            onClick={() => setNewContactModal(true)}
          >
            Create New
          </Button>
        </div>
        {isLoadingUpdateCustomerContacts ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button color="primary" onClick={doSubmit}>
            Confirm
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CustomerLocationContactsModal;
