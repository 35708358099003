import React, { useState } from "react";

import { useAuth } from "../../providers/authProvider";
import { useSidebar } from "../../providers/sidebarProvider";
import Icon from "../Icon";
import ProfileModal from "../ProfileModal";

const SidebarUser = () => {
  const [sidebarContext] = useSidebar();
  const [authContext] = useAuth();

  const [profileModal, setProfileModal] = useState();

  return (
    <div className="ps-sidebar-user d-flex justify-content-center align-items-center">
      <div
        onClick={() => setProfileModal(true)}
        data-testid="user-image"
        className="cursor-pointer user-image rounded-circle flex-shrink-0 border p-1 d-flex align-items-center justify-content-center"
      >
        <Icon name="user" size="md" />
      </div>
      {sidebarContext.isOpen ? (
        <div className="d-flex flex-column ms-2" style={{ width: 150 }}>
          <span className="text-start text-truncate text-dark fw-bold">
            {`${authContext.userData?.firstName} ${authContext.userData?.lastName}`}
          </span>
          <small className="text-start text-truncate text-dark">
            {`${authContext.userData?.role.name}`}
          </small>
        </div>
      ) : null}
      {profileModal ? <ProfileModal onClose={() => setProfileModal()} /> : null}
    </div>
  );
};

export default SidebarUser;
