import { useContext, useCallback } from "react";
import { FormTemplateContext, ELEMENT_STATUS } from "../index";
import ACTIONS from "../actions";
import { v1 as uuidv1 } from "uuid";

const useTemplate = () => {
  const context = useContext(FormTemplateContext);
  const { state, dispatch, getDefaultQuestionType } = context;

  const updateTemplateName = useCallback(
    (name) => {
      if (name === state.template.name) {
        return;
      }
      dispatch({ type: ACTIONS.UPDATE_TEMPLATE_NAME, payload: name });
    },
    [dispatch, state.template]
  );

  const setErrors = useCallback(
    (errors) => {
      dispatch({ type: ACTIONS.SET_ERRORS, payload: errors });
    },
    [dispatch]
  );

  const getSections = useCallback(() => {
    return Object.values(state.sections);
  }, [state.sections]);

  const initializeTemplate = useCallback(
    (template, prefix) => {
      let data = {
        template: {
          id: uuidv1(),
          name: "",
        },
        sections: {},
        groups: {},
        questions: {},
        options: {},
        activeTab: null,
      };
      if (!template) {
        const sectionId = uuidv1();
        const groupId = uuidv1();
        const questionId = uuidv1();

        data.sections = {
          [sectionId]: {
            id: sectionId,
            name: "",
            status: ELEMENT_STATUS.CREATE,
            order: 1,
          },
        };

        data.groups = {
          [groupId]: {
            id: groupId,
            name: "",
            sectionId,
            order: 1,
            status: ELEMENT_STATUS.CREATE,
          },
        };

        const defaultQuestionType = getDefaultQuestionType();

        data.questions = {
          [questionId]: {
            id: questionId,
            name: "",
            groupId,
            guidance: null,
            required: false,
            questionType: defaultQuestionType,
            questionTypeId: defaultQuestionType?.id,
            order: 1,
            status: ELEMENT_STATUS.CREATE,
          },
        };

        data.activeTab = sectionId;
      } else {
        const { sections = [], configuration, ...templateData } = template;
        data.template = templateData;
        data.configuration = configuration;

        sections.forEach((section) => {
          const { groups = [], ...sectionData } = section;
          data.sections[sectionData.id] = {
            ...sectionData,
            status: ELEMENT_STATUS.WITHOUT_CHANGE,
          };

          groups.forEach((group) => {
            const { questions = [], ...groupData } = group;
            data.groups[groupData.id] = {
              ...groupData,
              status: ELEMENT_STATUS.WITHOUT_CHANGE,
              sectionId: group[`${prefix}SectionId`],
            };

            questions.forEach((question) => {
              const { options = [], guidance, ...questionData } = question;
              data.questions[questionData.id] = {
                ...questionData,
                status: ELEMENT_STATUS.WITHOUT_CHANGE,
                questionTypeId: question[`${prefix}QuestionTypeId`],
                groupId: question[`${prefix}QuestionGroupId`],
                guidance: guidance
                  ? {
                      ...guidance,
                      status: ELEMENT_STATUS.WITHOUT_CHANGE,
                    }
                  : null,
              };

              options.forEach((option) => {
                data.options[option.id] = {
                  ...option,
                  questionId: option[`${prefix}QuestionId`],
                  status: ELEMENT_STATUS.WITHOUT_CHANGE,
                };
              });
            });
          });
        });
        data.activeTab = sections[0].id;
      }

      dispatch({ type: ACTIONS.INITIALIZE_TEMPLATE, payload: data });
    },
    [dispatch, getDefaultQuestionType]
  );

  const setQuestionTypes = useCallback(
    (questionTypes) => {
      dispatch({ type: ACTIONS.SET_QUESTION_TYPES, payload: questionTypes });
    },
    [dispatch]
  );

  const setConfiguration = useCallback(
    (configuration) => {
      dispatch({ type: ACTIONS.SET_CONFIGURATION, payload: configuration });
    },
    [dispatch]
  );

  const setUnsavedChanges = useCallback(
    (hasUnsavedChanges) => {
      dispatch({
        type: ACTIONS.SET_UNSAVED_CHANGES,
        payload: hasUnsavedChanges,
      });
    },
    [dispatch]
  );

  return {
    ...context,
    updateTemplateName,
    setErrors,
    getSections,
    initializeTemplate,
    setQuestionTypes,
    setConfiguration,
    setUnsavedChanges,
  };
};

export default useTemplate;
