import React, { useReducer, createContext, useContext } from "react";
import moment from "moment";

const JOB_DETAILS_TAB = "work-performed";
const JOB_DESCRIPTION_DETAILS_TAB = "job-description";
const CREW_TAB = "crews";
const TIME_TAB = "time";
const EXPENSES_TAB = "expenses";
const PER_DIEMS_TAB = "per-diem";
const EQUIPMENTS_TAB = "equipment";
const REPORTS_TAB = "reports";
const LABOR_BY_EMPLOYEE_REPORT_TAB = "labor-by-employee-report";
const LABOR_REPORT_TAB = "labor-report";
const SMART_REPORTS_TAB = "smart-reports";
const INSPECTIONS_TAB = "inspections";
const INSPECTIONS_LIST_TAB = "inspections-list";
const INSPECTIONS_TEMPLATE_TAB = "inspections-assigned-templates";
const SAFETY_TAB = "safety";
const SAFETY_LIST_TAB = "safety-list";
const SAFETY_TEMPLATE_TAB = "safety-assigned-templates";
const CLOSING_DETAILS_TAB = "closing-details";

const TABS = {
  JOB_DETAILS_TAB,
  JOB_DESCRIPTION_DETAILS_TAB,
  CREW_TAB,
  TIME_TAB,
  EXPENSES_TAB,
  PER_DIEMS_TAB,
  EQUIPMENTS_TAB,
  REPORTS_TAB,
  LABOR_BY_EMPLOYEE_REPORT_TAB,
  LABOR_REPORT_TAB,
  SMART_REPORTS_TAB,
  INSPECTIONS_TAB,
  INSPECTIONS_LIST_TAB,
  INSPECTIONS_TEMPLATE_TAB,
  SAFETY_TAB,
  SAFETY_LIST_TAB,
  SAFETY_TEMPLATE_TAB,
  CLOSING_DETAILS_TAB,
};

const ACTIONS = {
  REFRESH: "REFRESH",
  CHANGE_TAB: "CHANGE_TAB",
  SELECT_DATE: "SELECT_DATE",
  GET_WORK_ORDER_SUCCESS: "GET_WORK_ORDER_SUCCESS",
};

const initialState = {
  workOrderStatus: [],
  workOrder: null,
  activeTab: null,
  selectedDate: moment().format("YYYY-MM-DD"),
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORK_ORDER_SUCCESS: {
      const { payload } = data;
      const { workOrder = {} } = payload;
      const isDifferentWorkOrder = workOrder.id !== state.workOrder?.id;
      const activeTab = isDifferentWorkOrder
        ? JOB_DETAILS_TAB
        : state.activeTab || JOB_DETAILS_TAB;

      return {
        ...state,
        workOrder,
        activeTab,
      };
    }

    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh,
      };

      return { ...newState };
    }

    case ACTIONS.CHANGE_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeTab: tab,
      };
    }

    case ACTIONS.SELECT_DATE: {
      const { payload } = data;
      const { date } = payload;

      return {
        ...state,
        selectedDate: date,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const WorkOrderDetailsContext = createContext(initialState);

const WorkOrderDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <WorkOrderDetailsContext.Provider value={stateAndDispatch}>
      {children}
    </WorkOrderDetailsContext.Provider>
  );
};

export const useWorkOrderDetails = () => useContext(WorkOrderDetailsContext);

export { WorkOrderDetailsContext, WorkOrderDetailsProvider, ACTIONS, TABS };
