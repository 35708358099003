import React from "react";

import { Label } from "reactstrap";

import moment from "moment";

import { data, sharedHelper } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";
const TRAVEL_TIMES_PACKAGE = "traveltimes";

const EditTravelTimeHours = ({ travelTime = {}, onTravelTimeChanged }) => {
  const [authContext] = useAuth();

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    TRAVEL_TIMES_PACKAGE,
    START_END_TRAVEL_TIME_SETTING
  );

  const startTime = travelTime.startTime
    ? moment(travelTime.startTime).format("HH:mm")
    : null;

  const endTime = travelTime.endTime
    ? moment(travelTime.endTime).format("HH:mm")
    : null;

  let hours = travelTime.hours;
  if (startEndTimeSettingEnabled) {
    hours = (
      moment(travelTime.endTime).diff(moment(travelTime.startTime), "minutes") /
      60
    ).toFixed(2);
  }

  return (
    <div className="w-100">
      {startEndTimeSettingEnabled ? (
        <>
          <div
            className="d-flex align-items-start flex-column py-2 col-12 px-0"
            tag="div"
          >
            <Label>
              <small>Start Time</small>
              <small className="ms-1 text-danger">*</small>
            </Label>
            <DebounceInput
              debounceTimeout={900}
              className="form-control-redesign"
              required={true}
              step="60"
              placeholder="Enter a start time"
              data-testid="startTime"
              type="time"
              value={startTime || ""}
              onChange={(event) => {
                const mStartTime = moment(event.target.value, "HH:mm");
                if (travelTime.endTime) {
                  const mEndTime = moment(
                    moment(travelTime.endTime).format("HH:mm"),
                    "HH:mm"
                  );
                  const endTime = moment(travelTime.crewWorkDay.date)
                    .set("hours", mEndTime.get("hours"))
                    .set("minutes", mEndTime.get("minutes"));
                  if (mEndTime.isSameOrBefore(mStartTime)) {
                    endTime.add(1, "day");
                  }
                  travelTime = onTravelTimeChanged(
                    endTime.toISOString(),
                    "endTime",
                    travelTime
                  );
                }
                const startTime = moment(travelTime.crewWorkDay.date)
                  .set("hours", mStartTime.get("hours"))
                  .set("minutes", mStartTime.get("minutes"))
                  .toISOString();
                onTravelTimeChanged(startTime, "startTime", travelTime);
              }}
            />
          </div>
          <div
            className="d-flex align-items-start flex-column py-2 col-12 px-0"
            tag="div"
          >
            <Label className="d-flex align-items-start">
              <small>End Time</small>
              <small className="ms-1 text-danger">*</small>
              {sharedHelper.plusIndicatorEndTime(startTime, endTime)}
            </Label>
            <DebounceInput
              debounceTimeout={900}
              className="form-control-redesign"
              step="60"
              data-testid="endTime"
              placeholder="Enter an end time"
              type="time"
              value={endTime || ""}
              onChange={(event) => {
                if (!event.target.value.length) {
                  return onTravelTimeChanged(null, "endTime", travelTime);
                }
                const mEndTime = moment(event.target.value, "HH:mm");
                const endTime = moment(travelTime.crewWorkDay.date)
                  .set("hours", mEndTime.get("hours"))
                  .set("minutes", mEndTime.get("minutes"));
                if (travelTime.startTime) {
                  const mStartTime = moment(
                    moment(travelTime.startTime).format("HH:mm"),
                    "HH:mm"
                  );
                  if (mEndTime.isSameOrBefore(mStartTime)) {
                    endTime.add(1, "day");
                  }
                }
                onTravelTimeChanged(
                  endTime.toISOString(),
                  "endTime",
                  travelTime
                );
              }}
            />
          </div>
          {startTime && endTime ? (
            <div
              className="d-flex align-items-start flex-column py-2 col-12 px-0"
              tag="div"
            >
              <Label>
                <small>Hours</small>
                <small className="ms-1 text-danger">*</small>
              </Label>
              <DebounceInput
                debounceTimeout={900}
                data-testid="hours"
                className="form-control-redesign"
                placeholder="Enter a hours"
                value={hours}
                disabled={true}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div
          className="d-flex align-items-start flex-column py-2 col-12 px-0"
          tag="div"
        >
          <Label>
            <small>Hours</small>
            <small className="ms-1 text-danger">*</small>
          </Label>
          <DebounceInput
            debounceTimeout={900}
            data-testid="hours"
            className="form-control-redesign"
            type="number"
            placeholder="Enter a hours"
            required={true}
            max={24}
            min={0.1}
            step={0.1}
            value={hours}
            onChange={(event) =>
              onTravelTimeChanged(event.target.value, "hours", travelTime)
            }
          />
        </div>
      )}
    </div>
  );
};

export default EditTravelTimeHours;
