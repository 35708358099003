import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import { useGetAllWorkOrderCategory } from "../api/WorkOrderCategory.hooks";
import {
  useCreateWorkOrderStatus,
  useUpdateWorkOrderStatus,
} from "../api/WorkOrderStatus.hooks";

const { Loader, Select, CustomCheckbox } = components;

const WorkOrderStatusModal = ({ workOrderStatus = {}, onSubmit, onClose }) => {
  const [workOrderCategory, setWorkOrderCategory] = useState(
    workOrderStatus.workOrderCategory
      ? {
          label: workOrderStatus.workOrderCategory.name,
          value: workOrderStatus.workOrderCategory.id,
        }
      : null
  );

  const [workOrderCategories, setWorkOrderCategories] = useState();

  const [name, setName] = useState(workOrderStatus.name || "");
  const [order, setOrder] = useState(workOrderStatus.order || 1);
  const [isWoReadOnly, setIsWoReadOnly] = useState(
    workOrderStatus.isWoReadOnly || false
  );
  const [isAppList, setIsAppList] = useState(
    workOrderStatus.isAppList || false
  );

  const {
    isLoading: isLoadingCreateWorkOrderStatus,
    mutate: createWorkOrderStatus,
    data: createWorkOrderStatusData,
  } = useCreateWorkOrderStatus();

  const {
    data: workOrderCategoryData,
    isLoading: isLoadingCategory,
    get: getWorkOrderCategory,
  } = useGetAllWorkOrderCategory();

  useEffect(() => {
    getWorkOrderCategory();
  }, [getWorkOrderCategory]);

  useEffect(() => {
    if (workOrderCategoryData) {
      setWorkOrderCategories(workOrderCategoryData);
    }
  }, [workOrderCategoryData]);

  useEffect(() => {
    if (createWorkOrderStatusData) {
      sharedHelper.successToast(`Work order status created`);
      onSubmit();
    }
  }, [createWorkOrderStatusData, onSubmit]);

  const {
    isLoading: isLoadingUpdateWorkOrderStatus,
    update: updateWorkOrderStatus,
    data: updateWorkOrderStatusData,
  } = useUpdateWorkOrderStatus();

  useEffect(() => {
    if (updateWorkOrderStatusData) {
      sharedHelper.successToast(`Work order status saved`);
      onSubmit();
    }
  }, [updateWorkOrderStatusData, onSubmit]);

  const doSubmit = async (event) => {
    event.preventDefault();
    if (!workOrderCategory) {
      return sharedHelper.warningToast(`Please select a work order category.`);
    }
    if (workOrderStatus.id) {
      await updateWorkOrderStatus({
        id: workOrderStatus.id,
        name,
        workOrderCategoryId: workOrderCategory.value,
        order,
        isWoReadOnly,
        isAppList,
      });
    } else {
      await createWorkOrderStatus({
        name,
        workOrderCategoryId: workOrderCategory.value,
        order,
        isWoReadOnly,
        isAppList,
      });
    }
  };

  const categoriesSelect = useMemo(() => {
    return workOrderCategories?.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [workOrderCategories]);

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {workOrderStatus.id ? "Edit" : "Add"} Work Order Status
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateWorkOrderStatus ||
          isLoadingUpdateWorkOrderStatus ||
          isLoadingCategory ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter a name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <Label>
                  <span>Order</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  max="999"
                  min="1"
                  type="number"
                  placeholder="Enter an order"
                  name="order"
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  required
                />
              </FormGroup>
              {workOrderCategories ? (
                <FormGroup>
                  <FormGroup>
                    <Label>
                      <span>Category</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      placeholder="Select a category"
                      options={categoriesSelect}
                      value={workOrderCategory}
                      onChange={(selected) => {
                        setWorkOrderCategory(selected);
                      }}
                    />
                  </FormGroup>
                </FormGroup>
              ) : (
                <div className="min-width-50">
                  <Loader size="sm" />
                </div>
              )}
              <FormGroup className="mt-4">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setIsWoReadOnly(!isWoReadOnly)}
                >
                  <div className="flex-shrink-0">WO Read Only</div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={isWoReadOnly} />
                  </div>
                </ListGroupItem>
              </FormGroup>
              <FormGroup className="mt-4">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center cursor-pointer"
                  onClick={() => setIsAppList(!isAppList)}
                >
                  <div className="flex-shrink-0">Listed in APP</div>
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox checked={isAppList} />
                  </div>
                </ListGroupItem>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateWorkOrderStatus ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderStatusModal;
