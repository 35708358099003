import { BaseRoutes, settingsRoutes } from "./routes/Routes";

import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Page401 from "./pages/auth/Page401";

import { useGetAllPackages } from "./api/Packages.hooks";
import { data, helpers } from "@crewos/shared";
import UsersBilling from "./pages/billing/UsersBilling";
import ModulesBilling from "./pages/billing/ModulesBilling";
import BillingSummary from "./pages/billing/BillingSummary";
import SignIn from "./pages/auth/signIn/SignIn";
import Data from "./pages/Data";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

// Routes
const routes = [
  {
    icon: "database",
    name: "Data",
    path: "/data",
    package: "core",
    sidebar: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    component: Data,
    order: 3,
  },
  {
    name: "Billing",
    icon: "dollar-sign",
    sidebar: true,
    path: "/sa-billing",
    package: "core",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    order: 8,
    entries: [
      {
        path: `users`,
        name: "Users Report",
        sidebar: true,
        component: UsersBilling,
      },
      {
        path: `modules`,
        name: "Modules Report",
        sidebar: true,
        component: ModulesBilling,
      },
      {
        path: `summary`,
        name: "Summary Report",
        sidebar: true,
        component: BillingSummary,
      },
    ],
  },
  {
    name: "Billing",
    icon: "dollar-sign",
    sidebar: true,
    path: "/a-billing",
    package: "core",
    scopes: ["!IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    setting: "BILLING_SECTION_REGULAR_ADMINS",
    order: 9,
    entries: [
      {
        path: `users`,
        name: "Users Report",
        sidebar: true,
        component: UsersBilling,
      },
      {
        path: `modules`,
        name: "Modules Report",
        sidebar: true,
        component: ModulesBilling,
      },
      {
        path: `summary`,
        name: "Summary Report",
        sidebar: true,
        component: BillingSummary,
      },
    ],
  },
  ...settingsRoutes,
];

const Routes = createRoutes(routes);

const setCoreSidebarRoutes = ({ setSidebarContext }) => {
  const sidebarRoutes = routes.filter((settingRoute) => settingRoute.sidebar);

  setSidebarContext({
    action: SIDEBAR_ACTIONS.SET_ROUTES,
    payload: { routes: sidebarRoutes },
  });
};

export {
  Routes as CoreRoutes,
  BaseRoutes,
  setCoreSidebarRoutes,
  useGetAllPackages,
  Page404,
  Page500,
  Page401,
  SignIn,
};
