import React from "react";

import { Modal, ModalBody, ModalFooter } from "reactstrap";

import TwoFASetupForm from "./TwoFASetupForm";

const TwoFASetupModal = ({ ...props }) => {
  return (
    <Modal isOpen={true} onClosed={props.onClose} size="sm">
      <ModalBody>
        <TwoFASetupForm {...props} />
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

export default TwoFASetupModal;
