import React, { useState, useCallback, useMemo } from "react";
import moment from "moment";
import { Button } from "reactstrap";
import { components, getStatusColor } from "@crewos/shared";
import { useGetSafety } from "../../api/Safety.hooks";
import {
  SAFETY_DETAIL_ACTIONS,
  useSafetyDetail,
} from "../../providers/safetyDetailProvider";
import DeleteSafetyModal from "../safetyDetail/DeleteSafetyModal";
import SafetyDetailModal from "../safetyDetail/SafetyDetailModal";

const { StatusFilter, AdvanceTable, ImagesViewerModal } = components;

const filters = [
  { name: "In Progress", id: "IN_PROGRESS", index: 0 },
  { name: "Completed", id: "COMPLETED", index: 1 },
];

const getStatusColorByName = (name) => {
  const filterItem = filters.find((filter) => filter.id === name);
  if (!filterItem) {
    return {
      color: null,
      item: {},
    };
  }
  return {
    color: getStatusColor(filterItem.index, false),
    item: filterItem,
  };
};

const columns = (onViewInspection, onDelete) => [
  {
    accessor: "createdAt",
    header: "Created Date",
    headerProps: { className: "text-truncate", style: { width: 300 } },
    Cell: (rowData) => {
      const { createdAt } = rowData.row;
      return moment(createdAt).format("MM/DD/YYYY");
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: {
      className: "text-truncate text-start",
      style: { width: 300 },
    },
    cellProps: {
      className: "text-center",
    },
    Cell: (rowData) => {
      const { status } = rowData.row;
      const statusWithColor = getStatusColorByName(status);
      return (
        <div className="text-start" data-value={statusWithColor.item.name}>
          <span style={statusWithColor.color} className="p-2">
            {statusWithColor.item.name}
          </span>
        </div>
      );
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    disableSortBy: true,
    headerProps: { style: { width: 300 } },
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => {
      const { id } = rowData.row;
      return (
        <div className="d-flex gap-2 justify-content-end">
          <Button color="primary" onClick={() => onViewInspection(id)}>
            View Inspection
          </Button>
          <Button color="danger" onClick={() => onDelete(id)}>
            Delete
          </Button>
        </div>
      );
    },
  },
];

const SafetyList = ({ safetyList, onSafetyDelete }) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedSafetyId, setSelectedSafetyId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {
    data: safetyData,
    isLoading: safetyLoading,
    get: getSafety,
  } = useGetSafety();

  const { state, dispatch } = useSafetyDetail();

  const handleSelectFilter = useCallback((status) => {
    setSelectedFilter(status);
  }, []);

  const handleCloseViewSafety = useCallback(() => {
    dispatch({ type: SAFETY_DETAIL_ACTIONS.CLOSE_SAFETY_MODAL });
  }, [dispatch]);

  const handleViewSafety = useCallback(
    async (id) => {
      await getSafety({ id });
      dispatch({ type: SAFETY_DETAIL_ACTIONS.OPEN_SAFETY_MODAL });
    },
    [getSafety, dispatch]
  );

  const handleClosePreviewModal = useCallback(() => {
    dispatch({ type: SAFETY_DETAIL_ACTIONS.OPEN_SAFETY_MODAL });
  }, [dispatch]);

  const handleDelete = useCallback((id) => {
    setSelectedSafetyId(id);
    setIsDeleteModalOpen(true);
  }, []);

  const handleOnDelete = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedSafetyId(null);
    onSafetyDelete();
  }, [onSafetyDelete]);

  const handleCloseDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
    setSelectedSafetyId(null);
  }, []);

  const filteredSafety = useMemo(() => {
    return safetyList.filter((safety) => {
      if (!selectedFilter) {
        return true;
      }
      return safety.status === selectedFilter.id;
    });
  }, [safetyList, selectedFilter]);

  const isOpenSafetyDetail = useMemo(() => {
    return safetyData && !safetyLoading && state.isOpenSafetyModal;
  }, [safetyData, safetyLoading, state]);

  return (
    <div className="overflow-x-auto">
      <div className="d-flex align-items-center me-3 position-relative mb-3 small">
        <StatusFilter
          statuses={filters}
          selectedStatus={selectedFilter}
          setSelectedStatus={handleSelectFilter}
        />
      </div>
      <AdvanceTable
        headerClassName="text-muted small"
        columns={columns(handleViewSafety, handleDelete)}
        data={filteredSafety || []}
        pageSize={15}
      />
      <DeleteSafetyModal
        isOpen={isDeleteModalOpen}
        safetyId={selectedSafetyId}
        onClose={handleCloseDeleteModal}
        onDelete={handleOnDelete}
      />
      <SafetyDetailModal
        isOpen={isOpenSafetyDetail}
        safety={safetyData}
        onClose={handleCloseViewSafety}
        status={getStatusColorByName(safetyData?.status)}
      />
      {state.isOpenPreviewModal && (
        <ImagesViewerModal
          title="Attachments Images"
          items={state.attachments}
          onClose={handleClosePreviewModal}
          slide={false}
        />
      )}
    </div>
  );
};

export default SafetyList;
