import AddWorkTimeModal from "./components/AddWorkTimeModal";
import EditWorkTimeModal from "./components/EditWorkTimeModal";
import CrewWorkDayWorkTimes from "./components/CrewWorkDayWorkTimes";
import { useGetAllWorkTimeTypes } from "./api/WorkTimeTypes.hooks";
import { useGetWorkTimes } from "./api/WorkTimes.hooks";
import { data, sharedHelper, helpers, components } from "@crewos/shared";
import WorkTimeTypes from "./pages/settings/WorkTimeTypes";
import WorkTimeCollisions from "./pages/settings/WorkTimeCollisions";
import {
  calculateHoursWithExceptions,
  calculateExceptions,
} from "./helpers/workTimes";

const { Settings } = components;

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const WORKTIMES = "WORKTIMES";

const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Work Time Settings",
    path: `worktimes`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "worktimes",
    isSetting: true,
    category: "Work Time",
    component: Settings,
    order: 1,
  },
  {
    sidebar: true,
    icon: null,
    name: "Work Time Types",
    path: `worktimes/work-time-types`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "worktimes",
    isSetting: true,
    category: "Work Time",
    component: WorkTimeTypes,
    order: 2,
  },
  {
    sidebar: true,
    icon: null,
    name: "Time Collisions",
    path: `worktimes/work-time-collisions`,
    package: "worktimes",
    isSetting: true,
    category: "Work Time",
    component: WorkTimeCollisions,
    setting: "WORK_TIME_COLLISION_SECTION",
    order: 3,
  },
];

const Routes = createRoutes(routes);

const setWorkTimeSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "worktimes");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: WORKTIMES, order: 6 },
    });
  }
};

export {
  Routes as WorkTimeRoutes,
  setWorkTimeSidebarRoutes,
  EditWorkTimeModal,
  CrewWorkDayWorkTimes,
  AddWorkTimeModal,
  useGetAllWorkTimeTypes,
  useGetWorkTimes,
  calculateHoursWithExceptions,
  calculateExceptions,
};
