import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  useCreateNonJobTimeType,
  useRegenerateNonJobTimeType,
  useUpdateNonJobTimeType,
} from "../api/NonJobTimeTypes.hooks";

import { components, sharedHelper } from "@crewos/shared";

import { useGetAllWorkTimeTypes } from "@crewos/worktimes";

const {
  Icon,
  Select,
  Loader,
  ConfirmationModal,
  ConfirmationModalWithInput,
  RulesModal,
  CustomCheckbox,
  TooltipItem,
} = components;

const NonJobTimeTypeModal = ({ nonJobTimeType = {}, onSubmit, onClose }) => {
  // State variables for form fields
  const [shortName, setShortName] = useState(nonJobTimeType.shortName || "");
  const [name, setName] = useState(nonJobTimeType.name || "");
  const [billingType, setBillingType] = useState(
    nonJobTimeType.billingType || ""
  );
  const [autoGeneration, setAutoGeneration] = useState(
    nonJobTimeType.autoGeneration
  );
  const [isManuallyEntered, setIsManuallyEntered] = useState(
    nonJobTimeType.isManuallyEntered
  );
  const [generationRules, setGenerationRules] = useState(
    nonJobTimeType.generationRules || []
  );
  const [editGenerationRulesModal, setEditGenerationRulesModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();
  const [confirmationModalWithInput, setConfirmationModalWithInput] =
    useState();

  const [workTimeTypes, setWorkTimeTypes] = useState([]);

  const { get: getWorkTimeTypes, data: workTimeTypesData } =
    useGetAllWorkTimeTypes();

  useEffect(() => {
    getWorkTimeTypes();
  }, [getWorkTimeTypes]);

  useEffect(() => {
    if (workTimeTypesData) {
      setWorkTimeTypes(workTimeTypesData);
    }
  }, [workTimeTypesData]);

  // Hooks for creating and updating non job time types
  const {
    isLoading: isLoadingCreateNonJobTimeType,
    mutate: createNonJobTimeType,
    data: createNonJobTimeTypeData,
  } = useCreateNonJobTimeType();

  const {
    isLoading: isLoadingUpdateNonJobTimeType,
    update: updateNonJobTimeType,
    data: updateNonJobTimeTypeData,
  } = useUpdateNonJobTimeType();

  const {
    isLoading: isLoadingRegenerateNonJobTimeType,
    regenerate: regenerateNonJobTimeType,
    data: regenerateNonJobTimeTypeData,
  } = useRegenerateNonJobTimeType();

  // Effect to handle successful creation
  useEffect(() => {
    if (createNonJobTimeTypeData) {
      sharedHelper.successToast(`Non job time type created`);
      onSubmit();
    }
  }, [createNonJobTimeTypeData, onSubmit]);

  // Effect to handle successful update
  useEffect(() => {
    if (updateNonJobTimeTypeData) {
      sharedHelper.successToast(`Non job time type saved`);
      onSubmit();
    }
  }, [updateNonJobTimeTypeData, onSubmit]);

  // Effect to handle successful update
  useEffect(() => {
    if (regenerateNonJobTimeTypeData) {
      sharedHelper.successToast(`Non job times regenerated`);
    }
  }, [updateNonJobTimeTypeData, regenerateNonJobTimeTypeData]);

  // Handler for form submission
  const doSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      name,
      shortName,
      billingType,
      autoGeneration: Boolean(autoGeneration),
      isManuallyEntered: Boolean(isManuallyEntered),
      generationRules,
    };
    if (nonJobTimeType.id) {
      await updateNonJobTimeType({
        id: nonJobTimeType.id,
        ...payload,
      });
    } else {
      await createNonJobTimeType({ ...payload });
    }
  };

  const onDeleteGenerationRule = (index) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        generationRules.splice(index, 1);
        setGenerationRules([...generationRules]);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Generation Rule",
      body: `<span class="text-center">Do you confirm you want to delete this rule?</span>`,
      confirmText: "Delete",
    });
  };

  const onRegenerate = () => {
    setConfirmationModalWithInput({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async (overwriteOverwritten) => {
        setConfirmationModalWithInput();
        regenerateNonJobTimeType({
          id: nonJobTimeType.id,
          overwriteOverwritten,
        });
      },
      onClose: () => {
        setConfirmationModalWithInput();
      },
      title: "Regenerate Non Job Times",
      body: `
        <p class="text-center small">This action will regenerate non job times for past dates for all employees using current rules.</p>
        <span class="small">Check the option below if you also want to overwrite the overwritten values</span>
      `,
      confirmText: "Overwrite overwritten values",
      confirmType: "check",
      btnConfirmText: "Regenerate",
    });
  };

  const isLoading = useMemo(
    () =>
      isLoadingCreateNonJobTimeType ||
      isLoadingUpdateNonJobTimeType ||
      isLoadingRegenerateNonJobTimeType,
    [
      isLoadingCreateNonJobTimeType,
      isLoadingUpdateNonJobTimeType,
      isLoadingRegenerateNonJobTimeType,
    ]
  );

  const billingSelect = useMemo(() => {
    return [
      { label: "ADD", value: "ADD" },
      { label: "IGNORE", value: "IGNORE" },
      { label: "SUBTRACT", value: "SUBTRACT" },
    ];
  }, []);

  const defaultBilling = useMemo(() => {
    return billingSelect.find((option) => option.value === billingType);
  }, [billingType, billingSelect]);

  return editGenerationRulesModal ? (
    <RulesModal
      entity={"Hours"}
      workTimeTypes={workTimeTypes}
      defaultRule={editGenerationRulesModal}
      onSubmit={(generationRule) => {
        setEditGenerationRulesModal();
        if (generationRule.index !== undefined) {
          generationRules.splice(generationRule.index, 1, generationRule);
        } else {
          generationRules.push(generationRule);
        }
        setGenerationRules([...generationRules]);
      }}
      onClose={() => setEditGenerationRulesModal()}
    />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : confirmationModalWithInput ? (
    <ConfirmationModalWithInput {...confirmationModalWithInput} />
  ) : (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {nonJobTimeType.id ? "Edit Non Job Time Type" : "Add Non Job Time Type"}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoading && <Loader size="sm" />}
          {!isLoading && (
            <>
              <FormGroup>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter a name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Short Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter a short name"
                  name="shortName"
                  value={shortName}
                  onChange={(e) => setShortName(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label className="d-flex align-items-center">
                  <span>Billing Type</span>
                  <span className="text-danger ms-1">*</span>
                  <div className="ms-2">
                    <TooltipItem
                      id="tooltip-1"
                      title={`<span>
                              Define how hours affects the employee hours in the payroll
                              report<br/>
                              This is retroactive, if changed it will affect all non job times of type in the past too
                            </span>`}
                    >
                      <Icon
                        name="info"
                        className="text-primary"
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  </div>
                </Label>
                <Select
                  id="billingSelect"
                  name="billingSelect"
                  data-testid="billing-select"
                  value={defaultBilling}
                  onChange={(selected) => setBillingType(selected.value)}
                  placeholder="Select type"
                  options={billingSelect}
                  required
                />
              </FormGroup>
              <FormGroup>
                <ListGroup className="mt-3">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => setAutoGeneration(!autoGeneration)}
                  >
                    <div className="flex-shrink-0">Auto Generation</div>
                    <div className="min-width-50 d-flex justify-content-end align-items-center">
                      <div className="d-flex align-items-center me-3">
                        {nonJobTimeType.id && autoGeneration ? (
                          <TooltipItem
                            id="tooltip-2"
                            title={`<span>
                              Generate all employees for past dates
                            </span>`}
                            className="me-3"
                          >
                            <Icon
                              name="refresh-cw"
                              onClick={onRegenerate}
                              className="text-primary"
                            />
                          </TooltipItem>
                        ) : null}
                        <TooltipItem
                          id="tooltip-2"
                          title={`<span>
                              Enables auto generation of non job times of type using the rules<br/>
                              Auto generation is not retroactive. Will auto generate based on data entered from the day the rules and flag are set
                            </span>`}
                        >
                          <Icon
                            name="info"
                            className="text-primary"
                            style={{ paddingBottom: "1px" }}
                          />
                        </TooltipItem>
                      </div>
                      <CustomCheckbox checked={autoGeneration} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
              <FormGroup>
                <ListGroup className="mt-3">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => setIsManuallyEntered(!isManuallyEntered)}
                  >
                    <div className="flex-shrink-0">Manually Entered</div>
                    <div className="min-width-50 d-flex justify-content-end align-items-center">
                      <div className="me-3">
                        <TooltipItem
                          id="tooltip-3"
                          title={`<span>
                              Enables non job times of type to be managed by APP users<br/>
                              Not implemented yet
                            </span>`}
                        >
                          <Icon
                            name="info"
                            className="text-primary"
                            style={{ paddingBottom: "1px" }}
                          />
                        </TooltipItem>
                      </div>
                      <CustomCheckbox checked={isManuallyEntered} />
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </FormGroup>
              <FormGroup>
                <ListGroup>
                  <ListGroupItem className="d-flex justify-content-between align-items-center fw-bold bg-graylight">
                    <div className="d-flex align-items-center">
                      Rules
                      <div className="ms-2">
                        <TooltipItem
                          id="exception-tooltip"
                          title={
                            "Exception hours are ignored when evaluating rules"
                          }
                        >
                          <Icon name="info" className="text-warning" />
                        </TooltipItem>
                      </div>
                    </div>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => setEditGenerationRulesModal(true)}
                    >
                      Create
                    </Button>
                  </ListGroupItem>
                  {generationRules.length ? (
                    generationRules
                      .sort((x, y) => x.precedence - y.precedence)
                      .map((generationRule, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <span>{`${generationRule.precedence} - ${generationRule.name}`}</span>
                          <div className="d-flex align-items-center justify-content-end">
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() =>
                                setEditGenerationRulesModal({
                                  ...generationRule,
                                  index,
                                })
                              }
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              className="ms-2"
                              size="sm"
                              onClick={() => onDeleteGenerationRule(index)}
                            >
                              Delete
                            </Button>
                          </div>
                        </ListGroupItem>
                      ))
                  ) : (
                    <ListGroupItem className="d-flex justify-content-center align-items-center cursor-pointer">
                      No rules added
                    </ListGroupItem>
                  )}
                </ListGroup>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NonJobTimeTypeModal;
