import React, { useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { useGetData, useGetModels } from "../api/Data.hooks";
import { components } from "@crewos/shared";
import { DebounceInput } from "react-debounce-input";

const { Icon, Loader, Select, AdvanceTablePagination } = components;

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const Data = () => {
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [search, setSearch] = useState("");
  const [data, setData] = useState({ data: [] });
  const [models, setModels] = useState([]);
  const [model, setModel] = useState();
  const [refresh, setRefresh] = useState(false);

  const {
    data: dataData,
    isLoading: isLoadingData,
    get: getData,
  } = useGetData();

  const {
    data: modelsData,
    isLoading: isLoadingModels,
    get: getModels,
  } = useGetModels();

  useEffect(() => {
    getModels();
  }, [getModels]);

  useEffect(() => {
    if (model) {
      getData({
        page,
        pageSize: sizePerPage,
        model: model.value,
        search,
      });
    }
  }, [getData, search, page, model, refresh, sizePerPage]);

  useEffect(() => {
    if (dataData) {
      setData(dataData);
    }
  }, [dataData]);

  useEffect(() => {
    if (modelsData) {
      const models = modelsData.map((model) => ({
        value: model,
        label: model,
      }));
      setModels(models);
    }
  }, [modelsData]);

  const isLoading = useMemo(
    () => isLoadingModels || isLoadingData,
    [isLoadingData, isLoadingModels]
  );

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Data</h2>
            <small className="text-muted ms-2 pt-1">({data.count || 0})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ minWidth: 250 }}>
              <Select
                name="modelSelect"
                onChange={(selected) => setModel(selected)}
                value={model}
                placeholder="Choose a model"
                options={models}
                data-testid="model-select"
                className="me-3"
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search something"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              data-testid="refresh-button"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="d-flex flex-row flex-wrap w-100 mb-4">
            {isLoading ? (
              <Loader />
            ) : model ? (
              data.data.length ? (
                data.data.map((data) => (
                  <div
                    style={{ whiteSpace: "pre-wrap" }}
                    className="text-start col-6 p-2"
                    key={data.id}
                  >
                    <div className="bg-light rounded p-2 h-100">
                      <code>{JSON.stringify(data, null, 2)}</code>
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="text-muted small text-center w-100"
                  data-testid="no-data-message"
                >
                  No data available for this model
                </div>
              )
            ) : (
              <div
                className="text-muted small text-center w-100"
                data-testid="no-model-message"
              >
                Please select a model to see data
              </div>
            )}
          </div>
          <AdvanceTablePagination
            totalCount={data.count}
            pageCount={data.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};

export default Data;
