import React, { useEffect, useState } from "react";

import { useSetup2FA } from "../../api/2FA.hooks";
import { sharedHelper } from "../../helpers/sharedHelper";

import TwoFAForm from "./TwoFAForm";
import Loader from "../Loader";

const TwoFASetupForm = ({ onSubmit, onClose, userId, defaultTwoFASetup }) => {
  const [twoFASetup, setTwoFASetup] = useState(defaultTwoFASetup);

  const {
    isLoading: isLoadingSetup2FA,
    mutate: setup2FA,
    data: setup2FAData,
    error,
  } = useSetup2FA();

  useEffect(() => {
    if (error) {
      sharedHelper.errorToast(
        error.response.data.msg || "There was an error with your request."
      );
    }
  }, [error]);

  useEffect(() => {
    if (!twoFASetup) {
      setup2FA();
    }
  }, [twoFASetup, setup2FA]);

  useEffect(() => {
    if (setup2FAData) {
      setTwoFASetup(setup2FAData.data);
    }
  }, [setup2FAData]);

  return (
    <div>
      {isLoadingSetup2FA || !twoFASetup ? (
        <Loader size="sm" />
      ) : (
        <div className="d-flex flex-column align-items-center">
          <h2>Enable 2FA</h2>
          <p className="mb-0">Scan this QR code using a TOTP APP:</p>
          <img
            src={twoFASetup.qrcode}
            alt="QR Code"
            style={{ maxWidth: 200 }}
          />
          <p style={{ wordBreak: "break-word" }}>
            <strong>Or use this secret (store carefully):</strong>{" "}
            <i>{twoFASetup.secret}</i>
          </p>
          <TwoFAForm
            className="form-control"
            onComplete={onSubmit}
            onBack={onClose}
            userId={userId}
            isSetup={true}
          />
        </div>
      )}
    </div>
  );
};

export default TwoFASetupForm;
