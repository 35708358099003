import React, { useCallback } from "react";
import { Card, CardHeader, Row, Col, Button, Badge } from "reactstrap";
import InputEditable from "../InputEditable";
import { useFormTemplate } from "../../providers/FormTemplateProvider";
import Icon from "../Icon";

const FormTemplateHeader = ({
  template,
  headerMessage,
  onSaveTemplate,
  onPublishTemplate,
  isLoadingSaveTemplate,
  isReadOnly,
}) => {
  const {
    state: { hasUnsavedChanges },
    updateTemplateName,
    getErrors,
  } = useFormTemplate();

  const handleUpdateName = useCallback(
    ($event) => {
      const value = $event.target.value;
      updateTemplateName(value);
    },
    [updateTemplateName]
  );

  return (
    <Card className="mb-3 w-100 box-shadow-none">
      <CardHeader className="section-header">
        <Row className="align-items-center justify-content-between w-100">
          <Col className="d-flex align-items-center">
            <Icon name="file-circle-check" size="lg" />
            <InputEditable
              className="ms-1"
              onBlur={handleUpdateName}
              defaultValue={template.name}
              placeholder="Enter a template name"
              size="medium"
              errors={getErrors(template.id)}
              disabled={isReadOnly}
            />
          </Col>
          <Col className="d-flex flex-row align-items-center justify-content-end gap-3">
            <span className="text-muted small">{headerMessage}</span>
            {isReadOnly ? (
              <Badge color="primary" className="px-2" pill>
                Read Only
              </Badge>
            ) : (
              <>
                <Button
                  color="secondary"
                  onClick={onSaveTemplate}
                  disabled={isLoadingSaveTemplate || !hasUnsavedChanges}
                >
                  Save as Draft
                </Button>
                <Button
                  color="primary"
                  onClick={onPublishTemplate}
                  disabled={isLoadingSaveTemplate}
                >
                  Publish Template
                </Button>
              </>
            )}
          </Col>
        </Row>
      </CardHeader>
    </Card>
  );
};

export default FormTemplateHeader;
