import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import {
  useBulkDeleteTravelTime,
  useBulkUpdateTravelTime,
} from "../api/TravelTimes.hooks";

import EditTravelTimeHours from "./EditTravelTimeHours";

const { Icon, Loader, CustomCheckbox } = components;

const EditTravelTimeModal = ({ travelTimes, employee, onClose, onSubmit }) => {
  const [localEmployeeTimes, setLocalEmployeeTimes] = useState([]);
  const [currentEmployeeTimes, setCurrentEmployeeTimes] = useState([]);

  const {
    isLoading: isLoadingUpdateTravelTime,
    bulkUpdate: bulkUpdateTravelTimes,
    data: updateTravelTimeData,
  } = useBulkUpdateTravelTime();

  const { data: deleteTravelTimeData, bulkDeleteTravelTimes } =
    useBulkDeleteTravelTime();

  useEffect(() => {
    if (updateTravelTimeData || deleteTravelTimeData) {
      sharedHelper.successToast(
        `Travel times ${updateTravelTimeData ? "saved" : "deleted"}`
      );
      onSubmit(localEmployeeTimes);
    }
  }, [
    updateTravelTimeData,
    deleteTravelTimeData,
    localEmployeeTimes,
    onSubmit,
  ]);

  const onTravelTimeChanged = (data, field, travelTime) => {
    const editedTravelTime = {
      ...travelTime,
      [field]: data,
      touched: true,
      deleted: false,
    };
    const newLocalData = JSON.parse(JSON.stringify(localEmployeeTimes));
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === editedTravelTime.id),
      1,
      editedTravelTime
    );
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(newLocalData)));
    return editedTravelTime;
  };

  const onTravelTimeDeleted = (travelTime) => {
    const deletedTravelTime = {
      ...travelTime,
      deleted: true,
      touched: false,
    };
    const newLocalData = [...localEmployeeTimes];
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === deletedTravelTime.id),
      1,
      deletedTravelTime
    );
    setLocalEmployeeTimes([...newLocalData]);
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    const travelTimesToUpdate = localEmployeeTimes.filter((d) => d.touched);

    const travelTimesToDelete = localEmployeeTimes
      .filter((d) => d.deleted)
      .map((travelTime) => travelTime.id);

    if (!travelTimesToUpdate.length && !travelTimesToDelete.length) {
      sharedHelper.warningToast(`No changes applied`);
      return onClose();
    }

    if (travelTimesToUpdate.length) {
      bulkUpdateTravelTimes({ travelTimes: travelTimesToUpdate });
    }

    if (travelTimesToDelete.length) {
      bulkDeleteTravelTimes({
        travelTimes: travelTimesToDelete,
      });
    }
  };

  useEffect(() => {
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(travelTimes)));
    setCurrentEmployeeTimes(JSON.parse(JSON.stringify(travelTimes)));
  }, [travelTimes]);

  useEffect(() => {
    setCurrentEmployeeTimes(
      localEmployeeTimes.filter((travelTime) => !travelTime.deleted)
    );
  }, [localEmployeeTimes]);

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">
          <span>Edit Travel Time Entries</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {currentEmployeeTimes.length ? (
            currentEmployeeTimes.map((employeeTime) => {
              let travelTime = { ...employeeTime };
              return (
                <div
                  key={travelTime.id}
                  className="d-flex flex-column align-items-center justify-content-between pb-3"
                >
                  <div className="d-flex align-items-center justify-content-between col-12 px-0 mb-1">
                    <div className="d-flex align-items-center justify-content-start">
                      <small className="text-muted me-2">Employee:</small>
                      <small>{`${employee.firstName} ${employee.lastName}`}</small>
                    </div>
                    <Button
                      className="float-end d-flex align-items-center"
                      color="none"
                      size="sm"
                      onClick={() => onTravelTimeDeleted(travelTime)}
                      data-testid="delete-button"
                    >
                      <Icon name="trash" className="text-danger fs-4" />
                    </Button>
                  </div>
                  <div className="d-flex align-items-center justify-content-start w-100 py-2">
                    <Label
                      for={`isDriver-${travelTime.id}`}
                      check
                      className="cursor-pointer mb-0 d-flex align-items-center"
                    >
                      <CustomCheckbox
                        checked={travelTime.isDriver}
                        onClick={() =>
                          onTravelTimeChanged(
                            !travelTime.isDriver,
                            "isDriver",
                            travelTime
                          )
                        }
                        id="isDriver"
                        name="isDriver"
                        className="cursor-pointer d-flex align-items-center small"
                      />
                      <span className="ms-2">Is Driver</span>
                    </Label>
                  </div>
                  <EditTravelTimeHours
                    travelTime={travelTime}
                    onTravelTimeChanged={onTravelTimeChanged}
                  />
                  <div
                    className="d-flex align-items-start flex-column py-2 col-12 px-0"
                    tag="div"
                  >
                    <Label>
                      <small>Mileage</small>
                      <small className="ms-1 text-danger">*</small>
                    </Label>
                    <input
                      className="form-control-redesign"
                      type="number"
                      placeholder="Enter a mileage"
                      required={true}
                      max={9999999999}
                      min={0}
                      step={0.1}
                      value={travelTime.mileage}
                      onChange={(event) =>
                        onTravelTimeChanged(
                          event.currentTarget.value,
                          "mileage",
                          travelTime
                        )
                      }
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12 px-0 text-muted text-center mt-3">
              No travel times to show
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Discard
          </Button>
          {isLoadingUpdateTravelTime || isLoadingUpdateTravelTime ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button type="submit" color="primary">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditTravelTimeModal;
