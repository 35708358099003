import React, { useEffect, useState, useCallback, memo, useMemo } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import { components } from "@crewos/shared";
import { useGetWorkOrderCloseOut } from "../../api/WorkOrderCloseOut.hooks";
import ClosingDetailsSignatureModal from "./ClosingDetailsSignatureModal";

const { AdvanceTable, Icon } = components;

const columns = ({ onViewSignature }) => [
  {
    accessor: "updatedAt",
    header: "Date",
    disableFilter: true,
    disableSortBy: true,
    Cell: (rowData) => {
      const { createdAt } = rowData.row;
      return moment(createdAt).format("MM/DD/YYYY hh:mm:ss A");
    },
  },
  {
    accessor: "action",
    header: "Action",
    disableFilter: true,
    disableSortBy: true,
  },
  {
    accessor: "name",
    header: "Name",
    disableFilter: true,
    disableSortBy: true,
    Cell: (rowData) => {
      const { author } = rowData.row;
      if (!author) {
        return "-";
      }
      return `${author.firstName} ${author.lastName}`;
    },
  },
  {
    accessor: "signature",
    header: "Signature",
    disableFilter: true,
    disableSortBy: true,
    Cell: (rowData) => {
      const { signatureUrl, notSignatureReason } = rowData.row;
      return signatureUrl ? (
        <Icon
          name="file-text"
          color="success"
          onClick={() => onViewSignature(rowData.row)}
          className="cursor-pointer"
        />
      ) : (
        <span className="text-danger">{notSignatureReason}</span>
      );
    },
  },
];

const WOClosingDetails = ({ workOrderId }) => {
  const {
    data,
    isLoading,
    get: getWorkOrderCloseOut,
  } = useGetWorkOrderCloseOut();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onViewSignature = useCallback((rowData) => {
    setSelectedRow(rowData);
    setIsOpen(true);
  }, []);

  useEffect(() => {
    getWorkOrderCloseOut({ workOrderId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const woCloseOutRecords = useMemo(() => {
    return (data?.data || [])
      .map((item) => {
        if (item.revertedBy) {
          return [
            {
              ...item,
              action: "Closed",
            },
            {
              ...item,
              action: "Edited",
              author: item.revertedAuthor,
              createdAt: item.revertedAt,
              signatureUrl: null,
              notSignatureReason: null,
            },
          ];
        }
        return [
          {
            ...item,
            action: "Closed",
          },
        ];
      })
      .flat()
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [data]);

  return (
    <>
      <Card>
        <CardBody>
          <h2>Closing Details</h2>
          <AdvanceTable
            isLoading={isLoading}
            headerClassName="text-muted small"
            columns={columns({ onViewSignature })}
            data={woCloseOutRecords}
            pageSize={20}
            tableProps={{
              striped: false,
            }}
          />
        </CardBody>
      </Card>
      <ClosingDetailsSignatureModal
        isOpen={isOpen}
        toggle={toggle}
        data={selectedRow}
      />
    </>
  );
};

export default memo(WOClosingDetails);
