import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { v4 as uuidv4 } from "uuid";

import "./assets/scss/_app.scss";
import "tippy.js/dist/tippy.css";

const container = document.getElementById("root");
const root = createRoot(container);

const uniqueIdentifier = uuidv4();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [window.location.origin],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_ENV_APP || "local",
});

root.render(
  <React.Fragment>
    <App version={uniqueIdentifier} />
  </React.Fragment>
);
