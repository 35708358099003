import React from "react";
import { Container, Card, CardHeader, Button } from "reactstrap";
import StatusFilter from "../StatusFilter";
import DebounceInput from "react-debounce-input";
import Icon from "../Icon";

const ListTemplateWrapper = ({
  title,
  caption,
  filters,
  children,
  onRefresh,
  onSelectFilter,
  onSearch,
}) => {
  return (
    <Container fluid className="list-templates">
      <div className="section-card">
        <Card className="mb-0 w-100 box-shadow-none">
          <CardHeader className="section-header">
            <div className="text-dark flex-grow-1 d-flex align-items-center">
              <h2 className="mb-0 ">{title}</h2>
              <small className="text-muted ms-2 pt-1">{caption}</small>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="me-3">
                <DebounceInput
                  className="search border-0 form-control"
                  maxLength={50}
                  minLength={1}
                  debounceTimeout={900}
                  placeholder="Search templates"
                  onChange={onSearch}
                />
              </div>
              <div className="d-flex gap-2 align-items-center position-relative">
                <span className="flex-shrink-0">Filter by status:</span>
                <StatusFilter
                  statuses={filters}
                  setSelectedStatus={onSelectFilter}
                />
                <Button
                  size="sm"
                  className="ms-1 rounded-circle d-flex custom-rounded-button text-primary py-2"
                  color="white"
                  data-testid="refresh-button"
                  onClick={onRefresh}
                >
                  <Icon name="sync" />
                </Button>
              </div>
            </div>
          </CardHeader>
        </Card>
        {children}
      </div>
    </Container>
  );
};

export default ListTemplateWrapper;
