import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Card, CardHeader, Col } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import { useUpdateOrCreateTemplateFull } from "../api/Inspections.hooks";
import { relevantDiffDates } from "../helpers/time";
import {
  ORIGIN_PAGE,
  useInspectionTemplate,
} from "../providers/inspectionTemplateProvider";
import InputEditable from "./InputEditable";

const { Icon } = components;

const TemplateHeader = ({ origin, templateId, onSave, isReadOnly }) => {
  const {
    state: { template, hasUnsavedChanges },
    updateTemplate,
    getData,
    updateUnsavedChanges,
    getErrors,
    verifyData,
  } = useInspectionTemplate();

  const {
    isLoading: isLoadingSaveTemplate,
    mutate: saveTemplate,
    data: templateSavedData,
  } = useUpdateOrCreateTemplateFull(templateId);

  const navigate = useNavigate();

  const handleUpdateName = React.useCallback(
    ($event) => {
      const name = $event.target.value;
      updateTemplate({ ...template, name });
    },
    [template, updateTemplate]
  );

  const handleSave = React.useCallback(
    async (status) => {
      const errors = verifyData();
      if (Object.keys(errors).length > 0) {
        sharedHelper.warningToast("Complete all required fields");
        return;
      }
      const { data, failedUploads } = await getData();
      if (failedUploads) {
        sharedHelper.warningToast("Failed to upload guidance attachments");
        return;
      }
      if (data && data.template) {
        await saveTemplate({
          ...data.template,
          status,
          id: templateId,
        });
      }
    },
    [getData, saveTemplate, verifyData, templateId]
  );

  const handleSaveDraft = React.useCallback(async () => {
    await handleSave("DRAFT");
  }, [handleSave]);

  const handlePublish = React.useCallback(async () => {
    await handleSave("PUBLISHED");
  }, [handleSave]);

  useEffect(() => {
    if (templateSavedData) {
      updateTemplate(templateSavedData.data);
      updateUnsavedChanges(false);
      sharedHelper.successToast("Template saved");
      if (origin === ORIGIN_PAGE.CREATE) {
        navigate(`../edit/${templateSavedData.data.id}`, {
          relative: "path",
          replace: true,
        });
      } else {
        onSave();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSavedData, navigate]);

  const headerMessage = useMemo(() => {
    if (hasUnsavedChanges) {
      return "You have unsaved changes";
    }
    if (isLoadingSaveTemplate) {
      return "Saving...";
    }
    if (
      !hasUnsavedChanges &&
      !isLoadingSaveTemplate &&
      origin === ORIGIN_PAGE.UPDATE
    ) {
      return `Last updated ${relevantDiffDates(
        template.updatedAt,
        new Date()
      )} ago`;
    }
    return "";
  }, [hasUnsavedChanges, isLoadingSaveTemplate, template, origin]);

  return (
    <Card className="mb-3 w-100 box-shadow-none">
      <CardHeader className="section-header">
        <Col className="d-flex align-items-center">
          <Icon name="check-circle" className="fs-4" />
          <InputEditable
            className="ms-1"
            value={template.name}
            onChange={handleUpdateName}
            placeholder="Template Name"
            size="medium"
            errors={getErrors(template.id)}
            disabled={isReadOnly}
          />
        </Col>
        <Col className="d-flex flex-row align-items-center justify-content-end gap-3">
          <span className="text-muted small">{headerMessage}</span>
          {isReadOnly ? (
            <Badge color="primary" className="px-2" pill>
              Read Only
            </Badge>
          ) : (
            <>
              <Button
                color="secondary"
                onClick={handleSaveDraft}
                disabled={isLoadingSaveTemplate || !hasUnsavedChanges}
              >
                Save as Draft
              </Button>
              <Button
                color="primary"
                onClick={handlePublish}
                disabled={
                  (isLoadingSaveTemplate || !hasUnsavedChanges) &&
                  template.status === "PUBLISHED"
                }
              >
                Publish Template
              </Button>
            </>
          )}
        </Col>
      </CardHeader>
    </Card>
  );
};

export default TemplateHeader;
