import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import moment from "moment";

const ClosingDetailsSignatureModal = ({ isOpen, toggle, data }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Signature</ModalHeader>
      <ModalBody>
        <ListGroup className="small">
          <ListGroupItem className="d-flex justify-content-between align-items-start  bg-graylight">
            <div className="col-4 fw-bold">Signature:</div>
            <div className="col-8">
              <img
                src={data?.signatureUrl}
                alt="Signature"
                className="w-100 border-radius-default border border-white"
              />
            </div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-start">
            <div className="col-4 fw-bold">Signed By:</div>
            <div className="col-8 text-end">{data?.customerName || "-"}</div>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-start  bg-graylight">
            <div className="col-4 fw-bold">Signed At:</div>
            <div className="col-8 text-end">
              {moment(data?.signedAt).format("MM/DD/YYYY hh:mm A")}
            </div>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
    </Modal>
  );
};

export default ClosingDetailsSignatureModal;
