import React, { useState } from "react";

import {
  Input,
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

const NewCustomerLocationContactsModal = ({ onClose, onSubmit }) => {
  const [newContact, setNewContact] = useState({});

  const onSave = (event) => {
    event.preventDefault();
    onSubmit(newContact);
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <Form onSubmit={onSave}>
        <ModalHeader
          toggle={onClose}
          className="d-flex justify-content-between"
        >
          Add Contact for Location
        </ModalHeader>
        <ModalBody className="text-center">
          <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
            <thead>
              <tr className="bg-graylight small text-muted">
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter a name"
                    onChange={(event) =>
                      setNewContact({
                        ...newContact,
                        name: event.currentTarget.value,
                      })
                    }
                  />
                </td>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter an email"
                    type="email"
                    onChange={(event) =>
                      setNewContact({
                        ...newContact,
                        email: event.currentTarget.value.trim(),
                      })
                    }
                  />
                </td>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter a phone"
                    onChange={(event) =>
                      setNewContact({
                        ...newContact,
                        phone: event.currentTarget.value,
                      })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Discard
          </Button>
          <Button className="ms-2" color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NewCustomerLocationContactsModal;
