import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";

import moment from "moment";

import { components, data, sharedHelper } from "@crewos/shared";

import {
  useCreateExpenseAttachment,
  useDeleteExpenseAttachment,
} from "../api/ExpenseAttachments.hooks";
import { useUpdateExpense } from "../api/Expenses.hooks";
import { useGetAllExpenseTypes } from "../api/ExpenseTypes.hooks";

const {
  Icon,
  Select,
  Loader,
  ImagesViewer,
  ImagesViewerModal,
  ConfirmationModal,
  UploadPhotosModal,
} = components;

const { useAuth } = data;

const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

const STATUS_PENDING = "pending";
const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";

const MODE_PREVIEW = "MODE_PREVIEW";

const PREVIEW_WIDTH = 48;
const PREVIEW_HEIGHT = 48;

const ExpenseModal = ({ isReadOnly, onClose, onSubmit, expense }) => {
  const [authContext] = useAuth();

  const [imageModal, setImageModal] = useState();
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [localExpense, setLocalExpense] = useState(expense);

  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState();

  const { mutate: createExpenseAttachment, data: createExpenseAttachmentData } =
    useCreateExpenseAttachment();

  useEffect(() => {
    if (createExpenseAttachmentData) {
      sharedHelper.successToast(`Photos uploaded`);
      onSubmit();
    }
  }, [createExpenseAttachmentData, onSubmit]);

  const { data: deleteExpenseAttachmentData, deleteExpenseAttachment } =
    useDeleteExpenseAttachment();

  useEffect(() => {
    if (deleteExpenseAttachmentData) {
      sharedHelper.successToast(`Expense photo deleted`);
      onSubmit();
    }
  }, [deleteExpenseAttachmentData, onSubmit]);

  const {
    isLoading: isLoadingGetExpenseTypes,
    get: getExpenseTypes,
    data: expenseTypesData,
  } = useGetAllExpenseTypes();

  useEffect(() => {
    getExpenseTypes();
  }, [getExpenseTypes]);

  useEffect(() => {
    if (expenseTypesData) {
      setExpenseTypes(expenseTypesData);
    }
  }, [expenseTypesData]);

  const {
    isLoading: isUpdatingExpense,
    update: updateExpense,
    data: updateExpenseData,
  } = useUpdateExpense();

  useEffect(() => {
    if (updateExpenseData) {
      sharedHelper.successToast(`Expense saved`);
      onSubmit();
    }
  }, [updateExpenseData, onSubmit]);

  const onDeleteImage = (attachment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteExpenseAttachment(attachment.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Photo",
      body: `Are you sure you want to delete this photo?`,
      confirmText: "Delete",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (expense.status !== localExpense.status) {
      if (localExpense.status === STATUS_APPROVED) {
        localExpense.approvedAt = moment().toISOString();
        localExpense.approvedBy = authContext.userData.id;
        localExpense.declinedAt = null;
        localExpense.declinedBy = null;
        localExpense.declinedReason = null;
      } else {
        localExpense.approvedAt = null;
        localExpense.approvedBy = null;
        if (localExpense.status === STATUS_DECLINED) {
          localExpense.declinedAt = moment().toISOString();
          localExpense.declinedBy = authContext.userData.id;
        } else {
          localExpense.declinedAt = null;
          localExpense.declinedBy = null;
          localExpense.declinedReason = null;
        }
      }
    }
    await updateExpense({ ...localExpense });
  };

  const expenseExpenseTypesSelect = useMemo(() => {
    return expenseTypes.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [expenseTypes]);

  const defaultExpenseType = useMemo(() => {
    return expenseExpenseTypesSelect.find(
      (item) => item.value === localExpense.expenseTypeId
    );
  }, [expenseExpenseTypesSelect, localExpense.expenseTypeId]);

  const paymentTypeSelect = useMemo(() => {
    return [
      { value: PAYMENT_TYPE_CASH, label: "Cash" },
      { value: PAYMENT_TYPE_CARD, label: "Card" },
    ];
  }, []);

  const defaultPaymentType = useMemo(() => {
    return paymentTypeSelect.find(
      (item) => item.value === localExpense.paymentType
    );
  }, [paymentTypeSelect, localExpense.paymentType]);

  const statusSelect = useMemo(() => {
    return [
      { value: STATUS_PENDING, label: "Pending" },
      { value: STATUS_APPROVED, label: "Approved" },
      { value: STATUS_DECLINED, label: "Declined" },
    ];
  }, []);

  const defaultStatus = useMemo(() => {
    return statusSelect.find((item) => item.value === localExpense.status);
  }, [statusSelect, localExpense.status]);

  return confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : showAddPhotoModal ? (
    <UploadPhotosModal
      isMulti={true}
      title="Add Expense's Photos"
      onClose={() => setShowAddPhotoModal(false)}
      includeDescription={true}
      onSubmit={(data) =>
        createExpenseAttachment({
          ...data,
          expenseId: expense.id,
        })
      }
    />
  ) : imageModal ? (
    <ImagesViewerModal
      title="Expense's Photos"
      items={[
        {
          id: imageModal.id,
          title: imageModal.description,
          url: imageModal.attachmentUrl,
        },
      ]}
      onClose={() => setImageModal(false)}
      onDelete={isReadOnly ? null : onDeleteImage}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {isReadOnly ? "Expense Detail" : "Edit Expense"}
      </ModalHeader>
      {isUpdatingExpense ? (
        <Loader />
      ) : (
        <Form className="px-1" onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor="employee">
                    <span>Employee</span>
                  </Label>
                  <input
                    data-testid="employee"
                    className={`form-control${isReadOnly ? "" : "-redesign"}`}
                    isReadOnly={isReadOnly}
                    required={true}
                    placeholder="Enter an employee"
                    id="employee"
                    name="employee"
                    value={
                      localExpense.employee
                        ? `${localExpense.employee.firstName} ${localExpense.employee.lastName}`
                        : "-"
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="expenseExpenseTypesSelect">
                    <span>Expense Type</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  {isLoadingGetExpenseTypes ? (
                    <Loader size="sm" />
                  ) : (
                    <Select
                      id="expenseExpenseTypesSelect"
                      name="expenseExpenseTypesSelect"
                      data-testid="expenseExpenseTypesSelect"
                      value={defaultExpenseType}
                      onChange={(selected) =>
                        setLocalExpense({
                          ...localExpense,
                          expenseTypeId: selected.value,
                        })
                      }
                      placeholder="Select the expense type"
                      options={expenseExpenseTypesSelect}
                      disabled={isReadOnly}
                    />
                  )}
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="paymentTypeSelect">
                    <span>Payment Method</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <Select
                    id="paymentTypeSelect"
                    name="paymentTypeSelect"
                    data-testid="paymentTypeSelect"
                    value={defaultPaymentType}
                    onChange={(selected) =>
                      setLocalExpense({
                        ...localExpense,
                        paymentType: selected.value,
                      })
                    }
                    placeholder="Select the payment method"
                    options={paymentTypeSelect}
                    disabled={isReadOnly}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="amount">
                    <span>Amount</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <CurrencyInput
                    data-testid="amount"
                    className={`form-control${isReadOnly ? "" : "-redesign"}`}
                    disabled={isReadOnly}
                    required={true}
                    id="amount"
                    name="amount"
                    value={localExpense.amount || ""}
                    prefix="$"
                    decimalsLimit={2}
                    max={999999999}
                    allowNegativeValue={false}
                    placeholder="Enter an amount"
                    onValueChange={(value) =>
                      setLocalExpense({
                        ...localExpense,
                        amount: parseFloat(value),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="description">
                    <span>Description</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    data-testid="description"
                    className={`form-control${isReadOnly ? "" : "-redesign"}`}
                    isReadOnly={isReadOnly}
                    required={true}
                    id="description"
                    name="description"
                    rows={4}
                    placeholder="Enter a description"
                    type="textarea"
                    value={localExpense.description || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        description: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="statusSelect">
                    <span>Status</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <Select
                    id="statusSelect"
                    name="statusSelect"
                    data-testid="statusSelect"
                    value={defaultStatus}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        status: e.target.value,
                      })
                    }
                    placeholder="Select the status"
                    options={statusSelect}
                    disabled={isReadOnly}
                    required
                  />
                </FormGroup>
                {localExpense.status === STATUS_DECLINED ? (
                  <FormGroup>
                    <Label htmlFor="declinedReason">
                      <span>Declined Reason</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <input
                      data-testid="declinedReason"
                      className={`form-control${isReadOnly ? "" : "-redesign"}`}
                      isReadOnly={isReadOnly}
                      required={true}
                      id="declinedReason"
                      rows={4}
                      placeholder="Enter a reason"
                      type="textarea"
                      maxLength="255"
                      value={localExpense.declinedReason || ""}
                      onChange={(e) =>
                        setLocalExpense({
                          ...localExpense,
                          declinedReason: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                ) : null}
                <FormGroup className="mb-0">
                  <Label>
                    <span>Photos</span>
                  </Label>
                  <div className="d-flex mx-n1">
                    {isReadOnly ? null : (
                      <Col
                        style={{
                          maxWidth: PREVIEW_WIDTH,
                          maxHeight: PREVIEW_HEIGHT,
                          minWidth: PREVIEW_WIDTH,
                          minHeight: PREVIEW_HEIGHT,
                        }}
                        className="mb-1 mt-1 cursor-pointer px-0 mx-1 d-flex align-items-center justify-content-center"
                        onClick={() => setShowAddPhotoModal(true)}
                        data-testid="add-photo-button"
                      >
                        <Icon
                          name="plus-circle"
                          className="text-primary fs-4"
                        />
                      </Col>
                    )}
                    {expense.expenseAttachments.length ? (
                      expense.expenseAttachments.map((attachment, i) => (
                        <Col
                          key={attachment.id}
                          style={{
                            maxWidth: PREVIEW_WIDTH,
                            maxHeight: PREVIEW_HEIGHT,
                            minWidth: PREVIEW_WIDTH,
                            minHeight: PREVIEW_HEIGHT,
                          }}
                          className="my-1 cursor-pointer px-0 mx-1 border-radius-default overflow-hidden"
                          onClick={() => setImageModal(attachment)}
                        >
                          <ImagesViewer
                            mode={MODE_PREVIEW}
                            height={PREVIEW_WIDTH}
                            width={PREVIEW_HEIGHT}
                            showActions={false}
                            showCaption={false}
                            items={[
                              {
                                url: attachment.attachmentUrl,
                                title: attachment.description,
                              },
                            ]}
                          />
                        </Col>
                      ))
                    ) : isReadOnly ? (
                      <small className="col-12 text-muted">No photos</small>
                    ) : null}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          {isReadOnly ? (
            <ModalFooter className="d-flex flex-nowrap justify-content-end">
              <Button
                color="secondary"
                onClick={onClose}
                className="col-6 text-dark"
              >
                Close
              </Button>
            </ModalFooter>
          ) : (
            <ModalFooter className="d-flex flex-nowrap justify-content-center">
              <Button
                color="secondary"
                onClick={onClose}
                className="col-6 text-dark"
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" className="col-6">
                Save
              </Button>
            </ModalFooter>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default ExpenseModal;
