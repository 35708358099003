import React from "react";

import TooltipItem from "../TooltipItem";
import Icon from "../Icon";

const DynamicAttributeLabel = ({ dynamicAttribute }) => (
  <div className="d-flex align-items-center">
    <span>{dynamicAttribute.label}</span>
    {dynamicAttribute.required ? (
      <span className="text-danger ms-1">*</span>
    ) : null}
    {dynamicAttribute.description ? (
      <TooltipItem
        id={`description-tooltip-${dynamicAttribute.id}`}
        title={dynamicAttribute.description}
      >
        <Icon
          name="info"
          data-testid="info-icon"
          className="text-primary ms-2"
        />
      </TooltipItem>
    ) : null}
  </div>
);

export default DynamicAttributeLabel;
