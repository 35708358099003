import React, { forwardRef, useState, useCallback, useEffect } from "react";
import { Input } from "reactstrap";
import cn from "classnames";
import Icon from "./Icon";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const EMAIL_DELIMITER_KEYS = [",", ";", "Enter"];

const InputEmail = (
  {
    delimiter,
    value: valueProp,
    onChange: onChangeProp,
    className,
    emailContainerClassName,
    disabled,
    defaultValue,
    ...props
  },
  ref
) => {
  const [emails, setEmails] = useState([]);

  const handleKeyDown = useCallback(
    ($event) => {
      if (EMAIL_DELIMITER_KEYS.includes($event.key)) {
        $event.preventDefault();
        const email = $event.target.value;
        if (EMAIL_REGEX.test(email)) {
          const newEmails = Array.from(new Set([...emails, email]));
          setEmails(newEmails);
          $event.target.value = "";
          onChangeProp?.(newEmails);
        } else {
          const splitEmails = email.replace(/[;\s]/g, ",").split(",");
          if (splitEmails.length > 0) {
            const validEmails = splitEmails.filter((email) =>
              EMAIL_REGEX.test(email)
            );
            if (validEmails.length > 0) {
              const newEmails = Array.from(
                new Set([...emails, ...validEmails])
              );
              setEmails(newEmails);
              $event.target.value = "";
              onChangeProp?.(newEmails);
            }
          }
        }
      }
    },
    [emails, onChangeProp]
  );

  const handleRemoveEmail = useCallback(
    (email) => {
      const newEmails = emails.filter((e) => e !== email);
      setEmails(newEmails);
      onChangeProp?.(newEmails);
    },
    [emails, onChangeProp]
  );

  useEffect(() => {
    if (defaultValue) {
      setEmails(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Input
        type="email"
        ref={ref}
        {...props}
        className={cn("mb-2 w-100", className)}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {!disabled && (
        <div className={cn("d-flex flex-wrap gap-2", emailContainerClassName)}>
          {emails.map((email, index) => (
            <div
              key={`${email}-${index}`}
              className="badge text-bg-secondary py-2 px-3 rounded-pill fs-5 fw-normal"
            >
              {email}
              {!disabled && (
                <Icon
                  name="x-circle"
                  className="ms-2 text-dark cursor-pointer"
                  onClick={() => handleRemoveEmail(email)}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default forwardRef(InputEmail);
