import React, { useMemo, useState } from "react";

import {
  Input,
  Form,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import { data, sharedHelper, components } from "@crewos/shared";

const { useAuth } = data;
const { DynamicAttributeInput, DynamicAttributeLabel } = components;

const NewCustomerLocationAssetModal = ({ onClose, onSubmit }) => {
  const [authContext] = useAuth();
  const [newAsset, setNewAsset] = useState({
    dynamicAttributes: {},
  });

  const onSave = (event) => {
    event.preventDefault();
    onSubmit(newAsset);
  };

  const dynamicAttributes = useMemo(
    () =>
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        "customers",
        "ASSET_DYNAMIC_ATTRIBUTES"
      ) || [],
    [authContext.userData]
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <Form onSubmit={onSave}>
        <ModalHeader
          toggle={onClose}
          className="d-flex justify-content-between"
        >
          Add Asset for Location
        </ModalHeader>
        <ModalBody className="text-center">
          <Table className="col-12 px-0 mb-0 overflow-hidden" striped>
            <thead>
              <tr className="bg-graylight small text-muted">
                <th>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </th>
                <th>
                  <span>Model #</span>
                  <span className="text-danger ms-1">*</span>
                </th>
                <th>
                  <span>Serial #</span>
                  <span className="text-danger ms-1">*</span>
                </th>
                <th>
                  <span>Manufacturer</span>
                  <span className="text-danger ms-1">*</span>
                </th>
                {dynamicAttributes.map((dynamicAttribute) => (
                  <th key={dynamicAttribute.id}>
                    <DynamicAttributeLabel
                      dynamicAttribute={dynamicAttribute}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter a name"
                    onChange={(event) =>
                      setNewAsset({
                        ...newAsset,
                        name: event.currentTarget.value,
                      })
                    }
                    maxLength={50}
                  />
                </td>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter a model #"
                    onChange={(event) =>
                      setNewAsset({
                        ...newAsset,
                        modelNumber: event.currentTarget.value,
                      })
                    }
                    maxLength={50}
                  />
                </td>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter a serial #"
                    onChange={(event) =>
                      setNewAsset({
                        ...newAsset,
                        serialNumber: event.currentTarget.value,
                      })
                    }
                    maxLength={50}
                  />
                </td>
                <td>
                  <Input
                    className="border-0"
                    required={true}
                    placeholder="Enter a manufacturer"
                    onChange={(event) =>
                      setNewAsset({
                        ...newAsset,
                        manufacturer: event.currentTarget.value,
                      })
                    }
                    maxLength={50}
                  />
                </td>
                {dynamicAttributes.map((dynamicAttribute) => (
                  <td key={dynamicAttribute.id}>
                    <DynamicAttributeInput
                      className="form-control border-0"
                      dynamicAttribute={dynamicAttribute}
                      data={newAsset}
                      setData={setNewAsset}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Discard
          </Button>
          <Button className="ms-2" color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NewCustomerLocationAssetModal;
