import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  useCreateCustomerLocation,
  useUpdateCustomerLocation,
} from "../api/Customers.hooks";

import { components, sharedHelper } from "@crewos/shared";

import CustomerLocationForm from "./CustomerLocationForm";

const { Loader } = components;

const MODAL = "MODAL";

const CustomerLocationModal = ({
  customerLocation = { isActive: true },
  onClose,
  onSubmit,
}) => {
  const [customerLocationData, setCustomerLocationData] =
    useState(customerLocation);

  const {
    isLoading: isLoadingUpdateCustomerLocation,
    update: updateCustomerLocation,
    data: updateCustomerLocationData,
  } = useUpdateCustomerLocation();

  const {
    isLoading: isLoadingCreateCustomerLocation,
    mutate: createCustomerLocation,
    data: createCustomerLocationData,
  } = useCreateCustomerLocation();

  useEffect(() => {
    if (updateCustomerLocationData) {
      sharedHelper.successToast(`Customer location saved`);
      onSubmit();
    }
  }, [updateCustomerLocationData, onSubmit]);

  useEffect(() => {
    if (createCustomerLocationData) {
      sharedHelper.successToast(`Customer location created`);
      onSubmit();
    }
  }, [createCustomerLocationData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (customerLocationData.id) {
      await updateCustomerLocation({ ...customerLocationData });
    } else {
      await createCustomerLocation({ ...customerLocationData });
    }
  };

  return (
    <Modal isOpen={true} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {customerLocationData.id ? "Edit" : "Create"} Customer Location
        </ModalHeader>
        <ModalBody>
          {isLoadingCreateCustomerLocation ||
          isLoadingUpdateCustomerLocation ? (
            <Loader size="sm" />
          ) : (
            <CustomerLocationForm
              mode={MODAL}
              customerLocationData={customerLocationData}
              setCustomerLocationData={setCustomerLocationData}
            />
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomerLocationModal;
