import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import SafetyDetail from "./SafetyDetail";

const SafetyDetailModal = ({ isOpen, safety, onClose, status }) => {
  return (
    <Modal isOpen={isOpen} contentClassName="p-3 border-radius-md" size="lg">
      <ModalHeader toggle={onClose} className="z-1" />
      <ModalBody>
        <SafetyDetail safety={safety} status={status} />
      </ModalBody>
    </Modal>
  );
};

export default SafetyDetailModal;
