import React, { useEffect, useMemo, useState } from "react";

import { Row, FormGroup, Label } from "reactstrap";

import { useGetCustomers } from "@crewos/customers";

import { components, data, sharedHelper } from "@crewos/shared";

const { useAuth } = data;

const { Select, DatesSelector, DynamicAttributeInput, DynamicAttributeLabel } =
  components;

const PAGE_SIZE_CUSTOMERS = 30;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const WorkOrderForm = ({ workOrderData, setWorkOrderData }) => {
  const [authContext] = useAuth();
  const [customerSearch, setCustomerSearch] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [customerLocationSearch, setCustomerLocationSearch] = useState();
  const [selectedCustomerLocation, setSelectedCustomerLocation] = useState();

  const [selectedStatus, setSelectedStatus] = useState();

  const {
    data: customers,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  const {
    data: defaultCustomer,
    // isLoading: isLoadingDefaultCustomer,
    get: getDefaultCustomer,
  } = useGetCustomers();

  const customersData = useMemo(() => {
    return (defaultCustomer?.data || []).concat(customers?.data || []);
  }, [customers, defaultCustomer]);

  useEffect(() => {
    getDefaultCustomer({
      isDefault: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      authContext.userData.workOrderStatus &&
      workOrderData?.workOrderStatusId
    ) {
      const selectedStatus = authContext.userData.workOrderStatus?.find(
        (s) => s.id === workOrderData?.workOrderStatusId
      );
      if (selectedStatus) {
        setSelectedStatus({
          value: selectedStatus?.id,
          label: selectedStatus?.name,
        });
      } else {
        setSelectedStatus();
      }
    }
  }, [authContext.userData.workOrderStatus, workOrderData]);

  useEffect(() => {
    if (customersData) {
      const selectedCustomer = customersData.find(
        (s) => s.id === workOrderData?.customerId
      );
      if (selectedCustomer) {
        setSelectedCustomer({
          value: selectedCustomer.id,
          label: selectedCustomer.customerName,
        });
      } else {
        const defaultCustomer = customersData?.find(
          (customer) => customer.isDefault
        );
        if (defaultCustomer) {
          const defaultLocation = defaultCustomer.locations.find(
            (location) => location.isDefault
          );
          setWorkOrderData({
            ...workOrderData,
            customerId: defaultCustomer.id,
            customerLocationId: defaultLocation?.id,
          });
        } else {
          setSelectedCustomer();
        }
      }
    }
  }, [customersData, workOrderData, setWorkOrderData]);

  // TODO: Refactor this to use callbacks instead of useEffect to prevent infinite re-renders
  useEffect(() => {
    if (customersData) {
      const selectedCustomer = customersData.find(
        (s) => s.id === workOrderData?.customerId
      );
      if (selectedCustomer) {
        const selectedLocation = selectedCustomer.locations.find(
          (s) => s.id === workOrderData?.customerLocationId
        );
        if (selectedLocation) {
          setSelectedCustomerLocation({
            value: selectedLocation.id,
            label: sharedHelper.getAddress(selectedLocation),
          });
        } else {
          const defaultLocation = selectedCustomer.locations.find(
            (location) => location.isDefault
          );
          if (defaultLocation) {
            setSelectedCustomerLocation({
              value: defaultLocation.id,
              label: sharedHelper.getAddress(defaultLocation),
            });
          } else {
            setSelectedCustomerLocation();
          }
        }
      } else {
        setSelectedCustomerLocation();
      }
    }
  }, [customersData, workOrderData]);

  useEffect(() => {
    getCustomers({
      search: customerSearch,
      pageSize: PAGE_SIZE_CUSTOMERS,
      isDefault: "false",
    });
  }, [getCustomers, customerSearch]);

  const customersOptions = useMemo(() => {
    return customersData.map((option) => ({
      label: option.customerName,
      value: option.id,
    }));
  }, [customersData]);

  const customerLocationOptions = useMemo(() => {
    if (selectedCustomer && customersData) {
      const customer = customersData?.find(
        (customer) => customer.id === selectedCustomer.value
      );
      return customer?.locations.map((option) => ({
        label: sharedHelper.getAddress(option),
        value: option.id,
      }));
    }
    return [];
  }, [selectedCustomer, customersData]);

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const statusSelect = useMemo(() => {
    return authContext.userData.workOrderStatus?.map((status) => ({
      value: status.id,
      label: status.name,
    }));
  }, [authContext.userData.workOrderStatus]);

  const internalSelect = useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }, []);

  const defaultInternal = useMemo(() => {
    return internalSelect.find(
      (option) => option.value === workOrderData.isInternal
    );
  }, [workOrderData.isInternal, internalSelect]);

  const dynamicAttributes = useMemo(
    () =>
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        "workorders",
        "WO_DYNAMIC_ATTRIBUTES"
      ) || [],
    [authContext.userData]
  );

  return (
    <Row className="px-0">
      <FormGroup className="col-6 px-3">
        <Label>
          <span>Work Order #</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <input
          maxLength="100"
          type="text"
          className="form-control-redesign"
          placeholder="Enter a work order #"
          name="workOrderNumber"
          value={workOrderData.workOrderNumber || ""}
          onChange={(e) =>
            setWorkOrderData({
              ...workOrderData,
              workOrderNumber: e.target.value,
            })
          }
          required
        />
      </FormGroup>
      <FormGroup className="col-6 px-3">
        <Label>
          <span>Status</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <Select
          placeholder="Select the status"
          options={statusSelect}
          value={selectedStatus}
          onChange={(selected) => {
            setWorkOrderData({
              ...workOrderData,
              workOrderStatusId: selected?.value,
            });
          }}
        />
      </FormGroup>
      {workOrderData.isInternal ? null : (
        <>
          <FormGroup className="col-6 px-3">
            <Label>
              <span>Customer</span>
            </Label>
            <Select
              placeholder="Search customers"
              data-testid="customer-select"
              noOptionsMessage={() => "No customers found"}
              options={customersOptions}
              isSearchable
              isClearable
              inputValue={customerSearch}
              value={selectedCustomer}
              onInputChange={setCustomerSearch}
              isLoading={isLoadingCustomers}
              onChange={(selected) => {
                setWorkOrderData({
                  ...workOrderData,
                  customerId: selected?.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup className="col-6 px-3">
            <Label>
              <span>Customer Location</span>
            </Label>
            <Select
              placeholder="Search customer locations"
              data-testid="customer-location-select"
              noOptionsMessage={() => "No locations found"}
              options={customerLocationOptions}
              isSearchable
              isClearable
              inputValue={customerLocationSearch}
              value={selectedCustomerLocation}
              onInputChange={setCustomerLocationSearch}
              isLoading={isLoadingCustomers}
              onChange={(selected) => {
                setWorkOrderData({
                  ...workOrderData,
                  customerLocationId: selected?.value,
                });
              }}
            />
          </FormGroup>
        </>
      )}
      <FormGroup className="col-6 px-3" noMargin={true}>
        <DatesSelector
          defaultStartDate={workOrderData.startDate}
          defaultEndDate={workOrderData.endDate}
          onSubmit={(startDate, endDate) => {
            setWorkOrderData({
              ...workOrderData,
              startDate,
              endDate,
            });
          }}
        />
      </FormGroup>
      {isSuperAdmin ? (
        <FormGroup className="col-6 px-3">
          <Label>
            <span>Is Internal</span>
          </Label>
          <Select
            id="internalSelect"
            name="internalSelect"
            onChange={(selected) => {
              const isInternal = selected.value;
              const data = {
                ...workOrderData,
                isInternal,
              };
              if (isInternal) {
                workOrderData.customerId = null;
                workOrderData.customerLocationId = null;
              }
              setWorkOrderData(data);
            }}
            value={defaultInternal}
            options={internalSelect}
            required
          />
        </FormGroup>
      ) : null}
      {dynamicAttributes.map((dynamicAttribute) => (
        <FormGroup className="col-6 px-3" key={dynamicAttribute.id}>
          <Label>
            <DynamicAttributeLabel dynamicAttribute={dynamicAttribute} />
          </Label>
          <DynamicAttributeInput
            dynamicAttribute={dynamicAttribute}
            data={workOrderData}
            setData={setWorkOrderData}
          />
        </FormGroup>
      ))}
    </Row>
  );
};

export default WorkOrderForm;
