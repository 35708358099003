import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useCreateCustomer, useUpdateCustomer } from "../api/Customers.hooks";

import { components, sharedHelper } from "@crewos/shared";
import CustomerForm from "./CustomerForm";

const { Loader } = components;

const CustomerModal = ({
  customer = { isActive: true },
  onClose,
  onSubmit,
}) => {
  const [customerData, setCustomerData] = useState(customer);

  const {
    isLoading: isLoadingUpdateCustomer,
    update: updateCustomer,
    data: updateCustomerData,
  } = useUpdateCustomer();

  const {
    isLoading: isLoadingCreateCustomer,
    mutate: createCustomer,
    data: createCustomerData,
  } = useCreateCustomer();

  useEffect(() => {
    if (updateCustomerData) {
      sharedHelper.successToast(`Customer saved`);
      onSubmit();
    }
  }, [updateCustomerData, onSubmit]);

  useEffect(() => {
    if (createCustomerData) {
      sharedHelper.successToast(`Customer created`);
      onSubmit();
    }
  }, [createCustomerData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (customerData.id) {
      await updateCustomer({ ...customerData });
    } else {
      await createCustomer({ ...customerData });
    }
  };

  return (
    <Modal isOpen={true} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {customerData.id ? "Edit" : "Create"} Customer
        </ModalHeader>
        <ModalBody>
          {isLoadingCreateCustomer || isLoadingUpdateCustomer ? (
            <Loader size="sm" />
          ) : (
            <CustomerForm
              customerData={customerData}
              setCustomerData={setCustomerData}
            />
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CustomerModal;
