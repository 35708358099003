import { api } from "@crewos/shared";

const { useApiGet } = api;

const endpoint = `/api/data`;

export const useGetModels = () => {
  return useApiGet(`${endpoint}/models`);
};

export const useGetData = () => {
  return useApiGet(endpoint);
};
