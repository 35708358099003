import React, { useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useBlocker, useNavigate } from "react-router-dom";
import TemplateHeader from "./TemplateHeader";
import Sections from "./Sections";
import Groups from "./Groups";
import {
  ORIGIN_PAGE,
  useInspectionTemplate,
} from "../providers/inspectionTemplateProvider";
import { useGetQuestionTypes, useGetTemplate } from "../api/Inspections.hooks";
import { components } from "@crewos/shared";

const { Loader } = components;

const BlockNavigation = ({ isBlocked }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isBlocked && currentLocation.pathname !== nextLocation.pathname
  );

  const isOpen = useMemo(() => blocker.state === "blocked", [blocker.state]);

  const handleClose = useCallback(() => {
    blocker.reset();
  }, [blocker]);

  const handleWithOutSave = useCallback(() => {
    blocker.proceed();
  }, [blocker]);

  const handleSave = useCallback(() => {
    blocker.reset();
  }, [blocker]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (!isBlocked) {
        blocker.proceed();
      }
    }
  }, [blocker, isBlocked]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={handleClose} className="border-bottom-0">
        You have unsaved changes
      </ModalHeader>
      <ModalBody>
        <p>
          There are edits and progress from your current session that might get
          lost.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleWithOutSave}>
          Continue Without Saving
        </Button>
        <Button color="primary" onClick={handleSave}>
          Back to Edit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const FormTemplate = ({ id, origin, isReadOnly = false }) => {
  const navigate = useNavigate();
  const {
    state: { questionTypes, hasUnsavedChanges },
    updateQuestionTypes,
    setOrigin,
    setFullData,
    resetFullData,
  } = useInspectionTemplate();
  const { data: questionTypesData, get: getQuestionTypes } =
    useGetQuestionTypes();
  const {
    data: templateToUpdateData,
    get: getTemplateToUpdate,
    error: templateToUpdateError,
    isLoading: templateToUpdateIsLoading,
  } = useGetTemplate(id);

  const onSave = useCallback(() => {
    getTemplateToUpdate();
  }, [getTemplateToUpdate]);

  useEffect(() => {
    if (templateToUpdateData) {
      if (
        origin === ORIGIN_PAGE.UPDATE &&
        templateToUpdateData.totalInspections > 0
      ) {
        navigate(`/inspections/templates/view/${id}`, { replace: true });
      } else {
        setFullData(templateToUpdateData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateToUpdateData]);

  useEffect(() => {
    if (templateToUpdateError) {
      navigate("/inspections/templates", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateToUpdateError]);

  useEffect(() => {
    updateQuestionTypes(questionTypesData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionTypesData]);

  useEffect(() => {
    getQuestionTypes({});
    resetFullData();
    setOrigin(origin);
    if (origin === ORIGIN_PAGE.UPDATE || origin === ORIGIN_PAGE.VIEW) {
      getTemplateToUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!questionTypes || !questionTypes.length) {
    return null;
  }

  if (templateToUpdateIsLoading) {
    return <Loader />;
  }

  return (
    <div className="inspections-container inspections-create">
      <BlockNavigation
        isBlocked={hasUnsavedChanges && origin !== ORIGIN_PAGE.VIEW}
      />
      <TemplateHeader
        origin={origin}
        templateId={id}
        onSave={onSave}
        isReadOnly={isReadOnly}
      />
      <div className="d-flex mx-3">
        <Col xs={3} className="p-0">
          <Sections isReadOnly={isReadOnly} />
        </Col>
        <Col xs={9} className="p-0">
          <Groups isReadOnly={isReadOnly} />
        </Col>
      </div>
    </div>
  );
};

export default FormTemplate;
