import { components, data, helpers, sharedHelper } from "@crewos/shared";

import Users from "./pages/Users";
import UserRoles from "./pages/settings/UserRoles";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const { Settings } = components;

const USERS = "USERS";

// Routes
const routes = [
  {
    path: "/users",
    name: "Users",
    package: "users",
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    icon: "users",
    sidebar: true,
    component: Users,
  },
  {
    sidebar: true,
    name: "Users Settings",
    path: `users`,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "users",
    category: "Users",
    component: Settings,
    order: 1,
  },
  {
    sidebar: true,
    name: "User Roles",
    path: `users/user-roles`,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    package: "users",
    category: "Users",
    isSetting: true,
    component: UserRoles,
    order: 2,
  },
];

const Routes = createRoutes(routes);

const setUserSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter(
    (route) =>
      route.sidebar &&
      sharedHelper.routeMeetsRole(route, userData.role.userRoleScopes)
  );

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "users");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: {
        routes: sidebarRoutes,
        name: USERS,
        order: 6,
      },
    });
  }
};

export { setUserSidebarRoutes, Routes as UserRoutes };
