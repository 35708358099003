import React, { useState } from "react";

import { Link } from "react-router-dom";

import { Button, Form, FormGroup, Label } from "reactstrap";

import { api, components, sharedHelper } from "@crewos/shared";

const { authApi } = api;
const { Loader } = components;

const BaseForm = ({ onComplete }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const doLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = await authApi.login({
        email: email.toLowerCase(),
        password,
      });
      setLoading(false);
      if (!userData) {
        return null;
      }
      onComplete(userData);
    } catch (err) {
      setLoading(false);
      sharedHelper.errorToast(err);
    }
  };

  return (
    <Form
      onSubmit={doLogin}
      className="flex-grow-1 d-flex flex-column justify-content-start"
    >
      <FormGroup className="d-flex flex-column">
        <Label className="fw-bold" for="emailUser">
          Email
        </Label>
        <input
          className="mb-2 login-input"
          id="emailUser"
          data-test-id="email-input"
          maxLength="50"
          required={true}
          name="email"
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(event) => setEmail(event.currentTarget.value.trim())}
        />
      </FormGroup>
      <FormGroup className="d-flex flex-column">
        <Label className="fw-bold" for="passwordUser">
          Password
        </Label>
        <input
          className="login-input"
          id="passwordUser"
          required={true}
          maxLength="50"
          type="password"
          name="password"
          placeholder="Enter your password"
          value={password}
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
      </FormGroup>
      <div className="text-center mt-3 d-flex flex-column justify-content-center">
        {loading ? (
          <Loader size="sm" />
        ) : (
          <div className="w-100 d-flex">
            <Button
              id="signIn"
              color="primary"
              type="submit"
              size="lg"
              className="flex-grow-1"
            >
              LOGIN
            </Button>
          </div>
        )}
        <small className="mt-4 d-none">
          <Link to="/auth/reset">Forgot your password?</Link>
        </small>
      </div>
    </Form>
  );
};

export default BaseForm;
