import React, { useEffect, useMemo, useState } from "react";

import {
  Form,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { useUpdateEmployee, useCreateEmployee } from "../api/Employees.hooks";

import { useGetEmployeeRoles } from "../api/EmployeeRoles.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

const { Loader, Select } = components;
const { useAuth } = data;

const MAX_PAGE_SIZE = Number.MAX_SAFE_INTEGER;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const EmployeeModal = ({
  employee = { isActive: true, isInternal: false },
  onSubmit,
  onClose,
}) => {
  const [authContext] = useAuth();
  const [employeeData, setEmployeeData] = useState({ ...employee });

  const {
    data: employeeRoles,
    isLoading: isLoadingEmployeeRoles,
    get: getEmployeeRoles,
  } = useGetEmployeeRoles();

  const {
    isLoading: isUpdatingEmployee,
    update: updateEmployee,
    data: updateEmployeeData,
  } = useUpdateEmployee();

  const {
    isLoading: isLoadingCreateEmployee,
    mutate: createEmployee,
    data: createEmployeeData,
  } = useCreateEmployee();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (employee.id) {
      await updateEmployee({
        ...employeeData,
      });
    } else {
      await createEmployee({
        ...employeeData,
      });
    }
  };

  useEffect(() => {
    if (updateEmployeeData) {
      sharedHelper.successToast(`Employee saved`);
      onSubmit();
    }
  }, [updateEmployeeData, onSubmit]);

  useEffect(() => {
    if (createEmployeeData) {
      sharedHelper.successToast(`Employee created`);
      onSubmit();
    }
  }, [createEmployeeData, onSubmit]);

  useEffect(() => {
    getEmployeeRoles({
      isActive: true,
      pageSize: MAX_PAGE_SIZE,
      sortBy: "name",
    });
  }, [getEmployeeRoles]);

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const roleSelect = useMemo(() => {
    let options = [];

    employeeRoles?.data.forEach((role) => {
      options.push({
        value: role.id,
        label: role.name,
      });
    });

    if (employeeData.role && !employeeData.role.isActive) {
      options.push({
        value: employeeData.employeeRoleId,
        label: employeeData.role.name,
      });
    }

    return options;
  }, [employeeRoles, employeeData]);

  const defaultRole = useMemo(() => {
    return roleSelect.find(
      (role) => role.value === employeeData.employeeRoleId
    );
  }, [roleSelect, employeeData.employeeRoleId]);

  const statusSelect = useMemo(() => {
    return [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
  }, []);

  const defaultStatus = useMemo(() => {
    return statusSelect.find(
      (status) => status.value === employeeData.isActive
    );
  }, [statusSelect, employeeData.isActive]);

  const internalSelect = useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }, []);

  const defaultInternal = useMemo(() => {
    return internalSelect.find(
      (internal) => internal.value === employeeData.isInternal
    );
  }, [internalSelect, employeeData.isInternal]);

  return (
    <Modal isOpen={true} size="sm">
      <ModalHeader className="d-flex justify-content-between" toggle={onClose}>
        {employee.id ? "Edit" : "Create"} Employee
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <Row>
            {isUpdatingEmployee || isLoadingCreateEmployee ? (
              <Loader size="sm" />
            ) : (
              <Col>
                <FormGroup>
                  <Label>
                    <span>First Name</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="firstName"
                    placeholder="Enter a first name"
                    value={employeeData.firstName || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        firstName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Last Name</span>
                    <span className="text-danger ms-1">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="lastName"
                    placeholder="Enter a last name"
                    value={employeeData.lastName || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        lastName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Email</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="email"
                    placeholder="Enter an email"
                    value={employeeData.email || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        email: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Phone</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    type="text"
                    maxLength="255"
                    name="phone"
                    placeholder="Enter a phone"
                    value={employeeData.phone || ""}
                    onChange={(e) =>
                      setEmployeeData({
                        ...employeeData,
                        phone: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                {isLoadingEmployeeRoles ? (
                  <Loader size="sm" />
                ) : (
                  <FormGroup>
                    <Label>
                      <span>Role</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      name="roleSelect"
                      value={defaultRole}
                      onChange={(selected) =>
                        setEmployeeData({
                          ...employeeData,
                          employeeRoleId: selected.value,
                        })
                      }
                      placeholder="Select a role"
                      options={roleSelect}
                      required
                    />
                  </FormGroup>
                )}
                {employee.id ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Status</span>
                    </Label>
                    <Select
                      name="statusSelect"
                      value={defaultStatus}
                      options={statusSelect}
                      onChange={(selected) => {
                        setEmployeeData({
                          ...employeeData,
                          isActive: selected.value,
                        });
                      }}
                      required
                    />
                  </FormGroup>
                ) : null}
                {isSuperAdmin ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Is Internal</span>
                    </Label>
                    <Select
                      name="internalSelect"
                      value={defaultInternal}
                      options={internalSelect}
                      onChange={(selected) => {
                        setEmployeeData({
                          ...employeeData,
                          isInternal: selected.value,
                        });
                      }}
                      required
                    />
                  </FormGroup>
                ) : null}
                {employeeData.user ? (
                  <FormGroup>
                    <ListGroup className="small">
                      <ListGroupItem className="d-flex justify-content-center align-items-center fw-bold bg-graylight">
                        User
                      </ListGroupItem>
                      <ListGroupItem className="d-flex align-items-center">
                        {`${employeeData.user.firstName} ${employeeData.user.lastName}`}
                      </ListGroupItem>
                      <ListGroupItem className="d-flex align-items-center">
                        {`${employeeData.user.email}`}
                      </ListGroupItem>
                      <ListGroupItem className="d-flex align-items-center">
                        {employeeData.user.NFCToken || "No NFC token"}
                      </ListGroupItem>
                    </ListGroup>
                  </FormGroup>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EmployeeModal;
