import React from "react";

import moment from "moment";

import Button from "reactstrap/lib/Button";

import Icon from "./Icon";

const MonthSelector = ({
  loading,
  monthStart,
  setMonthStart,
  monthsPeriod = 1,
  className,
  color = "primary",
  arrowColor = "5",
}) => {
  const previousMonth = () =>
    loading
      ? false
      : setMonthStart(
          moment(monthStart, "MMM/YY")
            .add(monthsPeriod * -1, "month")
            .format("MMM/YY")
        );

  const nextMonth = () =>
    loading
      ? false
      : setMonthStart(
          moment(monthStart, "MMM/YY")
            .add(monthsPeriod, "month")
            .format("MMM/YY")
        );

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <Button
        color={color}
        className={`text-${arrowColor}`}
        onClick={previousMonth}
        data-testid="prev-month-button"
      >
        <Icon name="chevron-left" />
      </Button>
      <span
        className="px-2 text-center flex-shrink-0 text-muted"
        style={{ minWidth: 80 }}
      >
        {monthStart}
      </span>
      <Button
        color={color}
        className={`text-${arrowColor}`}
        onClick={nextMonth}
        data-testid="next-month-button"
      >
        <Icon name="chevron-right" />
      </Button>
    </div>
  );
};

export default MonthSelector;
