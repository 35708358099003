import { createContext, useContext, useReducer } from "react";

const initialState = {
  safety: null,
  attachments: [],
  isOpenInspectionModal: false,
  isOpenPreviewModal: false,
};

const SAFETY_DETAIL_ACTIONS = {
  SET_SAFETY: "SET_SAFETY",
  SET_ATTACHMENTS: "SET_ATTACHMENTS",
  OPEN_INSPECTION_MODAL: "OPEN_INSPECTION_MODAL",
  CLOSE_INSPECTION_MODAL: "CLOSE_INSPECTION_MODAL",
  OPEN_PREVIEW_MODAL: "OPEN_PREVIEW_MODAL",
  CLOSE_PREVIEW_MODAL: "CLOSE_PREVIEW_MODAL",
};

const SafetyDetailContext = createContext(initialState);

const safetyDetailReducer = (state, action) => {
  switch (action.type) {
    case SAFETY_DETAIL_ACTIONS.SET_SAFETY:
      return {
        ...state,
        safety: action.payload,
      };
    case SAFETY_DETAIL_ACTIONS.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case SAFETY_DETAIL_ACTIONS.OPEN_INSPECTION_MODAL:
      return {
        ...state,
        isOpenInspectionModal: true,
        isOpenPreviewModal: false,
      };
    case SAFETY_DETAIL_ACTIONS.CLOSE_INSPECTION_MODAL:
      return {
        ...state,
        isOpenInspectionModal: false,
      };
    case SAFETY_DETAIL_ACTIONS.OPEN_PREVIEW_MODAL:
      return {
        ...state,
        isOpenPreviewModal: true,
        isOpenInspectionModal: false,
      };
    case SAFETY_DETAIL_ACTIONS.CLOSE_PREVIEW_MODAL:
      return {
        ...state,
        isOpenPreviewModal: false,
      };
    default:
      return state;
  }
};

const SafetyDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(safetyDetailReducer, initialState);
  return (
    <SafetyDetailContext.Provider value={{ state, dispatch }}>
      {children}
    </SafetyDetailContext.Provider>
  );
};

const useSafetyDetail = () => {
  const context = useContext(SafetyDetailContext);
  if (!context) {
    throw new Error(
      "useSafetyDetail must be used within a SafetyDetailProvider"
    );
  }
  return context;
};

export { SafetyDetailProvider, useSafetyDetail, SAFETY_DETAIL_ACTIONS };
