import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DebounceInput } from "react-debounce-input";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import moment from "moment";

import { components, sharedHelper } from "@crewos/shared";

import {
  calculateHoursWithExceptions,
  useGetAllWorkTimeTypes,
} from "@crewos/worktimes";

import { useGetLaborReportByJob } from "../api/Reports.hooks";

import LaborCostDetail from "../components/LaborCostDetail";

const { Icon, WeekSelector, CustomPeriodSelector, PeriodTypeSelector } =
  components;

const TYPE_WEEKLY = "TYPE_WEEKLY";

const JobLaborReport = ({ workOrderId }) => {
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });
  const [detailModal, setDetailModal] = useState();

  const [dates, setDates] = useState({
    start: moment().startOf("isoWeek").format("YYYY-MM-DD"),
    end: moment().endOf("isoWeek").format("YYYY-MM-DD"),
  });

  const {
    data: workTimeTypes,
    isLoading: isLoadingWorkTimeTypes,
    get: getAllWorkTimeTypes,
  } = useGetAllWorkTimeTypes();

  const {
    data: reportData,
    isLoading: isLoadingReport,
    get: getLaborReport,
  } = useGetLaborReportByJob();

  useEffect(() => {
    if (reportData) {
      setData(reportData);
    }
  }, [reportData, setData]);

  useEffect(() => {
    getAllWorkTimeTypes({ search });
  }, [getAllWorkTimeTypes, refresh, search]);

  useEffect(() => {
    const params = {
      search,
      startDate: dates.start,
      endDate: dates.end,
    };
    if (workOrderId) {
      params.workOrderId = workOrderId;
    }
    getLaborReport(params);
  }, [getLaborReport, workOrderId, dates, refresh, workTimeTypes, search]);

  const handleChangePeriodType = useCallback((selectedPeriodType) => {
    setPeriodType((prev) => {
      if (prev.value === selectedPeriodType.value) {
        return prev;
      }
      if (selectedPeriodType.value === TYPE_WEEKLY) {
        setDates((dates) => ({
          start: moment(dates.start).startOf("isoWeek").format("YYYY-MM-DD"),
          end: moment(dates.start).endOf("isoWeek").format("YYYY-MM-DD"),
        }));
      }
      return selectedPeriodType;
    });
  }, []);

  const isLoading = useMemo(
    () => isLoadingWorkTimeTypes || isLoadingReport,
    [isLoadingWorkTimeTypes, isLoadingReport]
  );

  let total = 0;

  return (
    <Container fluid>
      <Card className="section-card box-shadow-none">
        <CardHeader className="section-header border-radius-default">
          <div className="text-dark flex-grow-1 d-flex align-items-center flex-shrink-0">
            <h2 className="mb-0">Labor Cost</h2>
            <small className="text-muted ms-2 pt-1">({data.count})</small>
          </div>
          <div className="ms-4 d-flex align-items-center justify-content-end flex-shrink-0">
            <PeriodTypeSelector
              periodType={periodType}
              setPeriodType={handleChangePeriodType}
            />
            {periodType.value === TYPE_WEEKLY ? (
              <WeekSelector
                className="me-3"
                loading={isLoading}
                weekStart={dates.start}
                setWeekStart={(startDate) => {
                  const endDate = moment(startDate)
                    .endOf("isoWeek")
                    .format("YYYY-MM-DD");
                  setDates({
                    start: startDate,
                    end: endDate,
                  });
                }}
              />
            ) : (
              <CustomPeriodSelector
                defaultStartDate={dates.start}
                defaultEndDate={dates.end}
                onSubmit={(startDate, endDate) => {
                  setDates({
                    start: moment(startDate).format("YYYY-MM-DD"),
                    end: moment(endDate).format("YYYY-MM-DD"),
                  });
                }}
              />
            )}
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search work type"
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <CardBody className="bg-transparent mt-1 px-0">
          <div className="row row-cols-6 g-3 justify-content-center">
            {workTimeTypes?.map((workTimeType) => {
              if (!data.data?.length) {
                return (
                  <div key={workTimeType.id}>
                    <Card className="h-100 box-shadow-none">
                      <CardBody className="pb-3 d-flex flex-column justify-content-center">
                        <h3 className="mb-0 text-center currency">
                          <span>0.00</span>
                        </h3>
                        <div className="mt-1 text-center small text-truncate">
                          {sharedHelper.capitalize(workTimeType.name)}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                );
              }
              const {
                workTimes = {},
                firstName,
                lastName,
                workOrderId,
                workOrderNumber,
              } = data.data[0];

              const entries = workTimes[workTimeType.shortName]
                ? Object.values(workTimes[workTimeType.shortName])
                    .flatMap((e) => e)
                    .filter((entry) => typeof entry === "object")
                    .sort((x, y) =>
                      moment(x.date).isBefore(moment(y.date)) ? -1 : 1
                    )
                : [];

              const isInProgress = Boolean(
                entries.find((wt) => wt.startTime && !wt.endTime)
              );

              const totalHours = entries.reduce(
                (p, c) => p + calculateHoursWithExceptions(c),
                0
              );

              const value = isInProgress
                ? "In Progress"
                : totalHours.toFixed(2);

              if (!isNaN(value)) {
                total += totalHours;
              }

              return (
                <div key={workTimeType.id}>
                  <Card className="h-100 box-shadow-none">
                    <CardBody className="pb-3 d-flex flex-column justify-content-center">
                      <h3 className="mb-0 text-center currency">
                        <span
                          className={value > 0 ? `text-link` : ""}
                          onClick={() => {
                            setDetailModal({
                              workTimeTypeName: workTimeType.name,
                              workTimeTypeId: workTimeType.id,
                              workOrderId,
                              workOrderNumber,
                              firstName,
                              lastName,
                            });
                          }}
                        >
                          {value}
                        </span>
                      </h3>
                      <div className="mt-1 text-center small text-truncate">
                        {sharedHelper.capitalize(workTimeType.name)}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              );
            })}
            <div className="flex-grow-1">
              <Card className="h-100 box-shadow-none">
                <CardBody
                  className="pb-3 d-flex flex-column justify-content-center"
                  style={{
                    backgroundColor: `color-mix(in srgb, ${process.env.REACT_APP_BASE_COLOR_LIGHT} 30%, white 70%)`,
                  }}
                >
                  <h3 className="mb-0 text-center currency">
                    <span>{total.toFixed(2)}</span>
                  </h3>
                  <div className="mt-1 text-center small text-truncate">
                    Total
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </CardBody>
      </Card>
      {detailModal ? (
        <LaborCostDetail
          {...detailModal}
          startDate={dates.start}
          endDate={dates.end}
          onClose={(touched) => {
            setDetailModal();
            if (touched) {
              setRefresh(!refresh);
            }
          }}
        />
      ) : null}
    </Container>
  );
};

export default JobLaborReport;
