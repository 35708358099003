import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import {
  attributeSchema,
  attributeUiSchema,
} from "../../helpers/dynamicAttributes";
import { useAuth } from "../../providers/authProvider";
import { sharedHelper } from "../../helpers/sharedHelper";
import "../../assets/scss/jsonschema.scss";

const AttributeFormModal = ({ attribute, onClose, onSubmit }) => {
  const [authContext] = useAuth();
  const [formData, setFormData] = useState(attribute || {});

  const dynamicAttributesFields = useMemo(
    () =>
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        "core",
        "DYNAMIC_ATTRIBUTES_FIELDS"
      ) || [],
    [authContext.userData]
  );

  useEffect(() => {
    setFormData(attribute || {});
  }, [attribute]);

  const isEdit = useMemo(() => Boolean(attribute.id), [attribute]);

  const handleSubmit = ({ formData }) => {
    if (!formData.id) {
      formData.id = formData.label.toLowerCase().replaceAll(" ", "_");
    }
    onSubmit(formData);
  };

  return (
    <Modal isOpen={true} toggle={onClose}>
      <ModalHeader toggle={onClose}>
        {isEdit ? "Edit Attribute" : "Add Attribute"}
      </ModalHeader>
      <ModalBody>
        <Form
          schema={attributeSchema(dynamicAttributesFields)}
          uiSchema={attributeUiSchema(dynamicAttributesFields, isEdit)}
          validator={validator}
          formData={formData}
          onChange={(e) => setFormData(e.formData)}
        ></Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => handleSubmit({ formData })}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AttributeFormModal;
