import React, { useEffect, useState } from "react";

import {
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";

import moment from "moment";
import { useUpdatePackage } from "../api/Packages.hooks";
import { sharedHelper } from "@crewos/shared";
import CurrencyInput from "react-currency-input-field";

const PackageDetailModal = ({ pkg, onClose, onSubmit }) => {
  const [cost, setCost] = useState(pkg.cost);

  const { data: updatePackageData, update: updatePackage } = useUpdatePackage();

  useEffect(() => {
    if (updatePackageData) {
      sharedHelper.successToast(`Setting saved`);
      onSubmit();
    }
  }, [onSubmit, updatePackageData]);

  const doSubmit = async (evt) => {
    evt.preventDefault();
    await updatePackage({ ...pkg, cost });
  };

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Package Detail
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <ListGroup className="small">
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2"
              tag="div"
            >
              <span>Monthly Cost</span>
              <div>
                <CurrencyInput
                  className="form-control"
                  required={true}
                  id="monthly-cost"
                  name="monthly-cost"
                  value={cost || ""}
                  prefix="$"
                  decimalsLimit={2}
                  max={999999999}
                  allowNegativeValue={false}
                  placeholder="Enter a cost"
                  onValueChange={(value) => setCost(parseFloat(value))}
                />
              </div>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-graylight fw-bold"
              tag="div"
            >
              Active Periods
            </ListGroupItem>
            <ListGroupItem
              className="d-flex flex-column justify-content-between align-items-start py-2"
              tag="div"
            >
              {pkg.activePeriods.length ? (
                <Table
                  className="col-12 px-0 mb-0 overflow-hidden my-1"
                  striped
                  size="small"
                >
                  <thead>
                    <tr className="bg-graylight small">
                      <th className="text-start">Period</th>
                      <th className="text-center">Start</th>
                      <th className="text-end">End</th>
                    </tr>
                  </thead>
                  <tbody className="small">
                    {pkg.activePeriods
                      .sort((x, y) =>
                        moment(y.start).isBefore(moment(x.start) ? 1 : -1)
                      )
                      .map((period, index) => (
                        <tr key={period.start}>
                          <td className="text-start">#{index + 1}</td>
                          <td className="text-center">
                            {moment(period.start).format("YYYY/MM/DD")}
                          </td>
                          <td className="text-end">
                            {period.end
                              ? moment(period.end).format("YYYY/MM/DD")
                              : "-"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <span>No periods</span>
              )}
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between align-items-center">
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default PackageDetailModal;
