import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import {
  useBulkDeleteWorkTime,
  useBulkUpdateWorkTime,
} from "../api/WorkTimes.hooks";

import EditWorkTimeHours from "./EditWorkTimeHours";

const { Icon, Loader } = components;

const EditWorkTimeModal = ({ employee, workTimes, onClose, onSubmit }) => {
  const [localEmployeeTimes, setLocalEmployeeTimes] = useState([]);
  const [currentEmployeeTimes, setCurrentEmployeeTimes] = useState([]);

  const {
    isLoading: isLoadingUpdateWorkTime,
    bulkUpdate: bulkUpdateWorkTimes,
    data: updateWorkTimeData,
  } = useBulkUpdateWorkTime();

  const { data: deleteWorkTimeData, bulkDeleteWorkTimes } =
    useBulkDeleteWorkTime();

  useEffect(() => {
    if (updateWorkTimeData || deleteWorkTimeData) {
      sharedHelper.successToast(
        `Work times ${updateWorkTimeData ? "saved" : "deleted"}`
      );
      onSubmit(localEmployeeTimes);
    }
  }, [updateWorkTimeData, deleteWorkTimeData, localEmployeeTimes, onSubmit]);

  const onWorkTimeDeleted = (workTime) => {
    const deletedWorkTime = {
      ...workTime,
      deleted: true,
      touched: false,
    };
    const newLocalData = [...localEmployeeTimes];
    newLocalData.splice(
      localEmployeeTimes.findIndex((d) => d.id === deletedWorkTime.id),
      1,
      deletedWorkTime
    );
    setLocalEmployeeTimes([...newLocalData]);
  };

  const doSubmit = async (event) => {
    event.preventDefault();

    const workTimesToUpdate = localEmployeeTimes.filter((d) => d.touched);

    const workTimesToDelete = localEmployeeTimes
      .filter((d) => d.deleted)
      .map((workTime) => workTime.id);

    if (!workTimesToUpdate.length && !workTimesToDelete.length) {
      sharedHelper.warningToast(`No changes applied`);
      return onClose();
    }

    if (workTimesToUpdate.length) {
      bulkUpdateWorkTimes({ workTimes: workTimesToUpdate });
    }

    if (workTimesToDelete.length) {
      bulkDeleteWorkTimes({ workTimes: workTimesToDelete });
    }
  };

  useEffect(() => {
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(workTimes)));
    setCurrentEmployeeTimes(JSON.parse(JSON.stringify(workTimes)));
  }, [workTimes]);

  useEffect(() => {
    setCurrentEmployeeTimes(
      localEmployeeTimes.filter((workTime) => !workTime.deleted)
    );
  }, [localEmployeeTimes]);

  const onWorkTimeChanged = (value, field, workTime) => {
    const editedWorkTime = {
      ...workTime,
      [field]: value,
      touched: true,
      deleted: false,
    };
    const newData = JSON.parse(JSON.stringify(localEmployeeTimes));
    newData.splice(
      localEmployeeTimes.findIndex((d) => d.id === editedWorkTime.id),
      1,
      editedWorkTime
    );
    setLocalEmployeeTimes(JSON.parse(JSON.stringify(newData)));
    return editedWorkTime;
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">
          <span>Edit Work Time Entries</span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {currentEmployeeTimes.length ? (
            currentEmployeeTimes.map((employeeTime) => {
              let workTime = { ...employeeTime };
              return (
                <div
                  key={workTime.id}
                  className="d-flex flex-column align-items-center justify-content-between pb-3"
                >
                  <div className="d-flex align-items-center justify-content-between col-12 px-0 mb-1">
                    <div className="d-flex align-items-center justify-content-start">
                      <small className="text-muted me-2">Employee:</small>
                      <small>{`${employee.firstName} ${employee.lastName}`}</small>
                    </div>
                    <Button
                      className="float-end d-flex align-items-center"
                      color="none"
                      size="sm"
                      onClick={() => onWorkTimeDeleted(workTime)}
                      data-testid="delete-button"
                    >
                      <Icon name="trash" className="text-danger fs-4" />
                    </Button>
                  </div>
                  <div
                    className="d-flex align-items-start flex-column py-2 col-12 px-0"
                    tag="div"
                  >
                    <Label>
                      <small>Type</small>
                      <small className="ms-1 text-danger">*</small>
                    </Label>
                    <input
                      className="form-control-redesign"
                      type="text"
                      maxLength="50"
                      placeholder="Enter a type"
                      required={true}
                      readOnly={true}
                      value={workTime.workTimeType.name}
                    />
                  </div>
                  <EditWorkTimeHours
                    workTime={workTime}
                    onWorkTimeChanged={onWorkTimeChanged}
                  />
                </div>
              );
            })
          ) : (
            <div className="col-12 px-0 text-muted text-center mt-3">
              No work times to show
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Discard
          </Button>
          {isLoadingUpdateWorkTime || isLoadingUpdateWorkTime ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button type="submit" color="primary">
              Save
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditWorkTimeModal;
