import { api } from "@crewos/shared";

const { useApiGet } = api;

const endpoint = `/api/billing`;

export const useGetUsersBilling = () => {
  return useApiGet(`${endpoint}/users`);
};

export const useGetModulesBilling = () => {
  return useApiGet(`${endpoint}/modules`);
};

export const useGetGeneralBilling = () => {
  return useApiGet(`${endpoint}/general`);
};
