import React from "react";
import { Route, Outlet } from "react-router-dom";
import ScopeFilter from "../components/ScopeFilter";

const getRoutes = ({ route, index }) => {
  const { entries, component, package: pkg, path } = route;

  const routePath = route.isSetting ? `/settings/${path}` : path;

  let element = null;

  if (component) {
    const Component = component;
    element = (
      <ScopeFilter route={route}>
        <Component pkg={pkg} />
      </ScopeFilter>
    );
  } else if (entries && entries.length > 0) {
    element = (
      <ScopeFilter route={route}>
        <Outlet />
      </ScopeFilter>
    );
  } else {
    // No component and no entries; return null to render nothing
    return null;
  }

  return (
    <Route path={routePath} element={element} key={index}>
      {entries &&
        entries.map((entry, index) =>
          getRoutes({
            route: {
              ...entry,
              package: entry.package || route.package,
              setting: entry.setting || route.setting,
            },
            index,
          })
        )}
    </Route>
  );
};

const createRoutes = (routes) => {
  return routes.map((route, index) => getRoutes({ route, index }));
};

export { getRoutes, createRoutes };
