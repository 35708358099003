import React, { useMemo, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import moment from "moment";

import {
  defaultOperators,
  formatQuery,
  QueryBuilder,
  toFullOption,
  ValueEditor,
} from "react-querybuilder";

import { QueryBuilderBootstrap } from "@react-querybuilder/bootstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import "bootstrap-icons/font/bootstrap-icons.scss";
import "react-querybuilder/dist/query-builder.css";

const { useAuth } = data;
const { Icon, Select, CustomCheckboxWithLabel, DatesSelector, TooltipItem } =
  components;

const EXCLUDE_OPERATORS = [
  "between",
  "notIn",
  "in",
  "notBetween",
  "contains",
  "beginsWith",
  "endsWith",
  "doesNotContain",
  "doesNotBeginWith",
  "doesNotEndWith",
  "null",
  "notNull",
];

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";

const OPERATORS = defaultOperators.filter(
  (op) => !EXCLUDE_OPERATORS.includes(op.value)
);

const WORK_TIME_PACKAGE = "worktimes";

const validator = (r) => !!r.value;

const RulesModal = ({ defaultRule, workTimeType = {}, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const isEdit = defaultRule && typeof defaultRule === "object";

  const [rule, setRule] = useState(
    isEdit ? defaultRule.query : { combinator: "and", rules: [] }
  );

  const [dates, setDates] = useState(
    isEdit
      ? {
          startDate: defaultRule.startDate || moment().format("YYYY-MM-DD"),
          endDate: defaultRule.endDate || moment().format("YYYY-MM-DD"),
        }
      : {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        }
  );

  const [name, setName] = useState(isEdit ? defaultRule.name : "");
  const [action, setAction] = useState(isEdit ? defaultRule.action : "");

  const [openDates, setOpenDates] = useState(
    isEdit ? !defaultRule.startDate || !defaultRule.endDate : true
  );

  const [amount, setAmount] = useState(isEdit ? defaultRule.amount : "");

  const fields = useMemo(() => {
    let workFields = [
      {
        name: `workHours`,
        label: `Work hours`,
        placeholder: "Enter hours",
        operators: OPERATORS,
        validator,
      },
    ];
    const isWorkTimeStartEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
      authContext.userData?.packages,
      WORK_TIME_PACKAGE,
      START_END_WORK_TIME_SETTING
    );
    if (isWorkTimeStartEndTimeSettingEnabled) {
      workFields = [
        ...workFields,
        {
          name: `startTime`,
          label: `Work start time`,
          placeholder: "Enter time (hh:mm)",
          operators: OPERATORS,
          validator,
        },
        {
          name: `endTime`,
          label: `Work end time`,
          placeholder: "Enter time (hh:mm)",
          operators: OPERATORS,
          validator,
        },
      ];
    }
    return workFields.map((o) => toFullOption(o));
  }, [authContext.userData]);

  const doSubmit = (evt) => {
    evt.preventDefault();
    if (!openDates && !dates.startDate) {
      return sharedHelper.warningToast(
        "Start date is required when open dates is disabled"
      );
    }
    if (!openDates && !dates.endDate) {
      return sharedHelper.warningToast(
        "End date is required when open dates is disabled"
      );
    }
    if (rule.rules.find((rule) => rule.value === "")) {
      return sharedHelper.warningToast("All values are required");
    }
    onSubmit({
      name,
      query: rule,
      condition: formatQuery(rule, {
        format: "cel",
        fields,
      }),
      action,
      startDate: openDates ? null : dates.startDate,
      endDate: openDates ? null : dates.endDate,
      amount,
      index: defaultRule?.index,
    });
  };

  const controlElements = {
    valueEditor: (props) => {
      const fieldName =
        typeof props.field === "string" ? props.field : props.field.name;
      if (fieldName === "startTime" || fieldName === "endTime") {
        const value = props.value
          ? moment.utc(props.value, "HH:mm").local().format("HH:mm")
          : "";
        return (
          <input
            type="time"
            className="form-control"
            value={value}
            placeholder="Enter a value"
            onChange={(e) => {
              const value = moment(e.target.value, "HH:mm")
                .utc()
                .format("HH:mm");
              return props.handleOnChange(value);
            }}
            required
          />
        );
      } else {
        return <ValueEditor {...props} />;
      }
    },
  };

  const actionSelect = useMemo(() => {
    return [
      { value: "ADD", label: "ADD" },
      { value: "IGNORE", label: "IGNORE" },
      { value: "SUBTRACT", label: "SUBTRACT" },
    ];
  }, []);

  const defaultAction = useMemo(() => {
    return actionSelect.find((option) => option.value === action);
  }, [action, actionSelect]);

  return (
    <Modal isOpen={true}>
      <ModalHeader className="d-flex justify-content-between" toggle={onClose}>
        {`${isEdit ? "Edit" : "Create"} Exception Rule`}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Rule Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="50"
              type="text"
              placeholder="Enter a name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className="d-flex align-items-center">
              <span>Action</span>
              <span className="text-danger ms-1">*</span>
              <div className="ms-2">
                <TooltipItem
                  id="tooltip-1"
                  title={`<span>
                            Define how time generated affects the work hours of type<br/>
                            This is NOT retroactive, if changed it will affect all work times of type from now on
                          </span>
                  `}
                >
                  <Icon
                    name="info"
                    className="text-primary"
                    style={{ paddingBottom: "1px" }}
                  />
                </TooltipItem>
              </div>
            </Label>
            <Select
              id="actionSelect"
              name="actionSelect"
              data-testid="action-select"
              value={defaultAction}
              onChange={(selected) => setAction(selected.value)}
              placeholder="Select the type"
              options={actionSelect}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>Hours</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              type="number"
              min={0}
              step={0.1}
              placeholder="Enter an exception time"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </FormGroup>
          {!openDates ? (
            <div className="d-flex align-items-center justify-content-end w-100">
              <DatesSelector
                defaultStartDate={dates.startDate}
                defaultEndDate={dates.endDate}
                onSubmit={(startDate, endDate) => {
                  setDates({
                    startDate,
                    endDate,
                  });
                }}
              />
            </div>
          ) : null}
          <FormGroup>
            <div className="d-flex align-items-center justify-content-between w-100">
              <Label>
                <span>Rule</span>
                <span className="text-danger ms-1">*</span>
              </Label>
            </div>
            <QueryBuilderBootstrap>
              <QueryBuilder
                fields={fields}
                query={rule}
                onQueryChange={setRule}
                controlElements={controlElements}
                showCombinatorsBetweenRules
              />
            </QueryBuilderBootstrap>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <div className="d-flex align-items-center justify-content-center">
            <Button
              color="secondary"
              onClick={onClose}
              className="me-2 text-dark"
            >
              Discard
            </Button>
            <CustomCheckboxWithLabel
              label="Open Dates"
              className="btn btn-info"
              color="info"
              checked={openDates}
              onClick={() => setOpenDates(!openDates)}
            />
          </div>
          <Button color="primary" type="submit" name="save">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RulesModal;
