import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { components, data } from "@crewos/shared";

const { Icon, Select, TooltipItem } = components;

const { useAuth } = data;

const SelectSmartReportModal = ({
  isReadOnly,
  workOrderId,
  selectedReportId,
  onClose,
  onSubmit,
}) => {
  const [authContext] = useAuth();
  const [reportId, setReportId] = useState(selectedReportId);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const reports = authContext.userData.smartReports.filter(
      (report) => report.context === (workOrderId ? "WORKORDER" : "GLOBAL")
    );
    setReports(reports);
  }, [workOrderId, authContext.userData.smartReports]);

  const doSubmit = async (evt) => {
    evt.preventDefault();
    onSubmit(reportId);
  };

  const reportSelect = useMemo(() => {
    return reports.map((report) => ({
      value: report.id,
      label: report.name,
    }));
  }, [reports]);

  const defaultReport = useMemo(() => {
    return reportSelect.find((report) => report.value === reportId);
  }, [reportId, reportSelect]);

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          <div className="d-flex align-items-center">
            <span>Select {!isReadOnly ? "Smart " : ""}Report</span>
            <TooltipItem
              className="font-size-regular fw-regular"
              id="context-tooltip"
              title={`${workOrderId ? "Work Order" : "Global"} Context`}
            >
              <Icon
                name="info"
                className="text-primary text-warning ms-2"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Report</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <Select
              id="reportSelect"
              name="reportSelect"
              data-testid="report-select"
              onChange={(selected) => {
                setReportId(selected.value);
              }}
              value={defaultReport}
              placeholder="Select a report"
              options={reportSelect}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit" data-testid="submit-button">
            Select
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SelectSmartReportModal;
