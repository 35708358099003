import AddTravelTimeModal from "./components/AddTravelTimeModal";
import EditTravelTimeModal from "./components/EditTravelTimeModal";
import CrewWorkDayTravelTimes from "./components/CrewWorkDayTravelTimes";

import { data, sharedHelper, helpers, components } from "@crewos/shared";

const { Settings } = components;

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const TRAVELTIMES = "TRAVELTIMES";

const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Travel Time Settings",
    path: `traveltimes`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "traveltimes",
    category: "Travel Time",
    component: Settings,
    order: 5,
    isSetting: true,
  },
];

const Routes = createRoutes(routes);

const setTravelTimeSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "traveltimes");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: TRAVELTIMES, order: 6 },
    });
  }
};

export {
  Routes as TravelTimeRoutes,
  setTravelTimeSidebarRoutes,
  EditTravelTimeModal,
  CrewWorkDayTravelTimes,
  AddTravelTimeModal,
};
