import { Row } from "reactstrap";
import React from "react";
import TemplateItem from "./TemplateItem";

const TemplatesList = ({ title, templates, onDeleteItem, onCopyItem }) => {
  return (
    <div className="d-flex flex-column py-2">
      <div className="font-size-1-2 fw-bold">{title}</div>
      {templates.length > 0 ? (
        <Row
          className="d-flex row-gap-4 mt-3"
          xxl={4}
          xl={3}
          lg={3}
          md={3}
          sm={2}
          xs={1}
        >
          {templates.map((template) => (
            <TemplateItem
              key={template.id}
              template={template}
              onDelete={onDeleteItem}
              onCopy={onCopyItem}
            />
          ))}
        </Row>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100 text-black-50">
          No templates found with {title.toLowerCase()} status
        </div>
      )}
    </div>
  );
};

export default TemplatesList;
