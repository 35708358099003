import React, { useEffect, useMemo, useState } from "react";

import moment from "moment";
import DatePicker from "react-datepicker";

import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  crewHelper,
  useCrews,
  useCrewWorkDays,
  CREW_WORK_DAY_ACTIONS,
} from "@crewos/crews";
import { useCreateExpense } from "../api/Expenses.hooks";

import { components, data, sharedHelper } from "@crewos/shared";
import CrewMembers from "./CrewMembers";

const { Select, Loader } = components;
const { useWorkOrderDetails, useAuth } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const AddExpenseModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [crewsContext] = useCrews();
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [crewsWithLeadForDate, setCrewsWithLeadForDate] = useState([]);
  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(
    workOrderDetails.selectedDate
  );

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [paymentTypeToAdd, setPaymentTypeToAdd] = useState({});
  const [descriptionToAdd, setDescriptionToAdd] = useState({});
  const [expenseTypeToAdd, setExpenseTypeToAdd] = useState({});
  const [amountToAdd, setAmountToAdd] = useState({});

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const { id: workOrderId } = workOrderDetails.workOrder;

  const {
    isLoading: isLoadingCreateExpense,
    mutate: createExpense,
    data: createExpenseData,
  } = useCreateExpense();

  useEffect(() => {
    if (createExpenseData && crew) {
      sharedHelper.successToast(`Expenses added`);
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH_CREW_SECTION,
        payload: { crewId: crew.id, section: "EXPENSES" },
      });
      onClose();
    }
  }, [
    onClose,
    createExpenseData,
    crewWorkDaysContext.crewWorkDays,
    setCrewWorkDaysContext,
    crew,
  ]);

  useEffect(() => {
    const crewsWithLeadForDate = crewsContext.crews.filter((crew) =>
      crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled, selectedDate)
    );
    setCrewsWithLeadForDate(crewsWithLeadForDate);
  }, [crewsContext.crews, selectedDate, employeeCrewPastDatesEnabled]);

  const doSubmit = async (event) => {
    event.preventDefault();
    const expenses = Object.keys(amountToAdd).map((employeeId) => ({
      employeeId,
      crewId: crew.id,
      amount: amountToAdd[employeeId] || 0,
      description: descriptionToAdd[employeeId],
      date: moment(selectedDate).format("YYYY-MM-DD"),
      paymentType: paymentTypeToAdd[employeeId],
      expenseTypeId: expenseTypeToAdd[employeeId],
      workOrderId,
    }));

    if (!expenses.length) {
      sharedHelper.warningToast("Add expenses to at least one employee");
    }
    await createExpense({ expenses });
  };

  const crewForDate = useMemo(
    () => (crew ? crewsWithLeadForDate.find((c) => c.id === crew.id) : false),
    [crew, crewsWithLeadForDate]
  );

  const crewSelect = useMemo(() => {
    return crewsWithLeadForDate.map((crew) => {
      const lead = crewHelper.getCrewLead(crew, employeeCrewPastDatesEnabled);
      return {
        label: lead
          ? `${lead.employee.firstName} ${lead.employee.lastName}`
          : "No Lead",
        value: crew.id,
      };
    });
  }, [crewsWithLeadForDate, employeeCrewPastDatesEnabled]);

  const defaultCrew = useMemo(() => {
    return crewSelect.find((c) => c.value === crew?.id);
  }, [crewSelect, crew]);

  const onSelectedCrew = (selected) => {
    const crew = crewsContext.crews.find((c) => c.id === selected.value);
    setCrew(crew);
  };

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-start">Add Expense</div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          {isLoadingCreateExpense ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup>
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>Date</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                <div className="d-flex col-12 align-items-center justify-content-between">
                  <DatePicker
                    selected={
                      selectedDate
                        ? moment(selectedDate).startOf("day").toDate()
                        : null
                    }
                    onChange={(date) => setSelectedDate(date)}
                    className="d-flex align-items-center justify-content-between form-control-redesign cursor-pointer"
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="d-flex col-12 align-items-center justify-content-start">
                  <span>{directAssignationEnabled ? "Employee" : "Crew"}</span>
                  <span className="text-danger ms-1">*</span>
                </div>
                {crewsWithLeadForDate.length ? (
                  <Select
                    id="crewSelect"
                    name="crewSelect"
                    data-testid={`${
                      directAssignationEnabled ? "employee" : "crew"
                    }-select`}
                    value={defaultCrew}
                    onChange={onSelectedCrew}
                    placeholder={`Select a ${
                      directAssignationEnabled ? "n Employee" : " Crew"
                    }`}
                    options={crewSelect}
                    required
                  />
                ) : (
                  <div className="text-muted small">
                    No crews for selected date
                  </div>
                )}
              </FormGroup>
            </>
          )}
          {selectedDate && crewForDate ? (
            <CrewMembers
              crew={crew}
              selectedDate={selectedDate}
              paymentTypeToAdd={paymentTypeToAdd}
              setPaymentTypeToAdd={setPaymentTypeToAdd}
              descriptionToAdd={descriptionToAdd}
              setDescriptionToAdd={setDescriptionToAdd}
              expenseTypeToAdd={expenseTypeToAdd}
              setExpenseTypeToAdd={setExpenseTypeToAdd}
              employeeEnabled={employeeEnabled}
              setEmployeeEnabled={setEmployeeEnabled}
              amountToAdd={amountToAdd}
              setAmountToAdd={setAmountToAdd}
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          {isLoadingCreateExpense ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddExpenseModal;
