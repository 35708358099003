import { api } from "@crewos/shared";

const { useApiGet, useApiMutation, HttpMethod } = api;

const inspectionApi = "/api/inspection";
const inspectionTemplateApi = "/api/inspection-template";
const inspectionTemplateWorkOrderApi = "/api/inspection-template-work-order";
const inspectionQuestionTypeApi = "/api/inspection-question-type";
const customerLocationAssetApi = "/api/asset";

// Inspection
export const useGetInspections = () => {
  return useApiGet(inspectionApi);
};

export const useDeleteInspection = () => {
  return useApiMutation(HttpMethod.DELETE, inspectionApi);
};

export const useGetInspection = () => {
  return useApiGet(inspectionApi);
};

// Inspection Template
export const useGetInspectionsTemplates = () => {
  return useApiGet(inspectionTemplateApi);
};

export const useUpdateOrCreateTemplateFull = (id) => {
  const method = !id ? HttpMethod.POST : HttpMethod.PUT;
  return useApiMutation(method, `${inspectionTemplateApi}/full`);
};

export const useGetTemplate = (id) => {
  return useApiGet(`${inspectionTemplateApi}/${id}`);
};

export const useDeleteTemplate = () => {
  return useApiMutation(HttpMethod.DELETE, inspectionTemplateApi);
};

export const useDuplicateTemplate = (id) => {
  return useApiMutation(
    HttpMethod.POST,
    `${inspectionTemplateApi}/${id}/duplicate`
  );
};

// Inspection Template Work Order
export const useGetTemplateWorkOrder = () => {
  return useApiGet(inspectionTemplateWorkOrderApi);
};

export const useCreateTemplateWorkOrder = () => {
  return useApiMutation(HttpMethod.POST, inspectionTemplateWorkOrderApi);
};

export const useDeleteInspectionTemplateWorkOrder = () => {
  return useApiMutation(HttpMethod.DELETE, inspectionTemplateWorkOrderApi);
};

// Inspection Question Type
export const useGetQuestionTypes = () => {
  return useApiGet(inspectionQuestionTypeApi);
};

// Customer Location Asset
export const useGetCustomerLocationAsset = () => {
  return useApiGet(customerLocationAssetApi);
};
