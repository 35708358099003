import React, { useMemo, useCallback, useState } from "react";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useFormSections } from "../../providers/FormTemplateProvider";
import InputEditable from "../InputEditable";
import DeleteModal from "../DeleteModal";
import Icon from "../Icon";

const FormTemplateSectionItem = ({ section, isReadOnly }) => {
  const {
    state,
    updateSectionName,
    copySection,
    deleteSection,
    setActiveTab,
    getErrors,
    getTotalQuestionsBySection,
  } = useFormSections();
  const totalQuestions = useMemo(
    () => getTotalQuestionsBySection(section.id),
    [getTotalQuestionsBySection, section.id]
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUpdateName = useCallback(
    ($event) => {
      const value = $event.target.value;
      updateSectionName(section.id, value);
    },
    [updateSectionName, section.id]
  );

  const handleCopySection = useCallback(() => {
    copySection(section);
  }, [copySection, section]);

  const handleDeleteSectionModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, [setIsModalOpen]);

  const handleDeleteSection = useCallback(() => {
    deleteSection(section.id);
    setIsModalOpen(false);
  }, [deleteSection, section.id]);

  return (
    <div onClick={() => setActiveTab(section.id)} className="cursor-pointer">
      <Row
        className={`w-100 align-items-center bg-transparent p-0 m-0 border-0 rounded-0 ${
          state.activeTab === section.id ? "active" : ""
        }`}
      >
        <Col sm={10} className="d-flex justify-content-start flex-column p-0">
          <InputEditable
            disabled={isReadOnly}
            onBlur={handleUpdateName}
            defaultValue={section.name}
            placeholder="Enter a section name"
            size="small"
            className="max-width-200"
            data-testid="section-name"
            errors={getErrors(section.id)}
          />
          <small className="text-muted fst-italic">
            {totalQuestions || 0} questions
          </small>
        </Col>
        {!isReadOnly && (
          <Col
            sm={1}
            className="d-flex justify-content-end align-items-center p-0"
          >
            <UncontrolledDropdown group className="ms-2">
              <DropdownToggle caret={false} color="white">
                <Icon name="more-vertical" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={handleCopySection}
                  className="d-flex align-items-center gap-2"
                  data-testid="copy-section"
                >
                  <Icon name="copy" />
                  <span className="ms-1">Copy</span>
                </DropdownItem>
                <DropdownItem
                  onClick={handleDeleteSectionModal}
                  className="d-flex align-items-center gap-2 text-danger"
                  data-testid="delete-section"
                >
                  <Icon name="trash" />
                  <span className="ms-1">Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        )}
      </Row>
      <DeleteModal
        title="Delete Section"
        name={section.name}
        isOpen={isModalOpen}
        onDelete={handleDeleteSection}
        onClose={() => setIsModalOpen((prev) => !prev)}
      />
    </div>
  );
};

export default FormTemplateSectionItem;
