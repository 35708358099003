import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import classnames from "classnames";

import { awsApi } from "../api/awsServices";
import { sharedHelper } from "../helpers/sharedHelper";
import ImageContainer from "./ImageContainer";
import Loader from "./Loader";

import "../assets/scss/uploadPhotosModal.scss";
import Icon from "./Icon";

const UploadPhotosModal = ({
  isMulti,
  title,
  includeDescription,
  onClose,
  onSubmit,
}) => {
  const hiddenFileInputPhoto = useRef(null);

  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState();

  const handleFiles = (selectedFiles) => {
    const filesArray = Array.from(selectedFiles);
    const selectedImages = [];
    const now = Date.now();

    const updatedFiles = filesArray.map(
      (image, index) =>
        new File([image], `${now}_${index}`, {
          type: image.type,
        })
    );

    setImages(updatedFiles);

    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        selectedImages.push(reader.result);
        if (selectedImages.length === filesArray.length) {
          setPreviewImages(selectedImages);
        }
      };
    });
  };

  const handleFileChange = (files) => {
    const validFiles = [];
    const invalidFiles = [];

    Array.from(files).forEach((file) => {
      if (file.type.startsWith("image/")) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file.name);
      }
    });

    if (invalidFiles.length) {
      sharedHelper.errorToast(
        `The following files are not valid images: ${invalidFiles.join(", ")}`
      );
    } else {
      handleFiles(files);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    handleFileChange(files);
  };

  const appendImageToFormData = (formData, image) => {
    formData.append("files", image);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("bucket", process.env.REACT_APP_IMAGES_BUCKET_NAME);
    images.forEach((image) => appendImageToFormData(formData, image));
    const response = await awsApi.uploadFile(formData);
    return response;
  };

  const handleUploadClick = async () => {
    setLoading(true);
    try {
      const { urls } = await handleUpload();
      await onSaveImages(urls);
    } catch (error) {
      sharedHelper.errorToast(
        error.message || "There was an error with your request."
      );
    } finally {
      setLoading(false);
    }
  };

  const onSaveImages = async (images = []) => {
    for (const key in images) {
      const url = images[key];
      await onSubmit({
        attachmentUrl: url,
        description,
      });
    }
  };

  return (
    <Modal
      isOpen={true}
      onClosed={onClose}
      size="xl"
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
    >
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title ? title : `Upload Photo${isMulti ? "s" : ""}`}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="m-1">
              {includeDescription ? (
                <FormGroup>
                  <Label htmlFor="description">
                    <span>Description</span>
                  </Label>
                  <input
                    data-testid="description"
                    className="form-control-redesign mb-4"
                    id="description"
                    name="description"
                    rows={4}
                    maxLength={255}
                    placeholder="Enter a description"
                    type="textarea"
                    value={description || ""}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </FormGroup>
              ) : null}
              <input
                ref={hiddenFileInputPhoto}
                value=""
                multiple={isMulti}
                type="file"
                accept="image/*"
                className="text-truncate"
                placeholder="Select the file(s)"
                onChange={(e) => handleFileChange(e.target.files)}
                style={{ display: "none" }}
              />
              <div
                onDrop={handleDrop}
                className={classnames("upload-photos__dropzone", {
                  "preview-images": previewImages.length,
                })}
              >
                <Icon name="plus" className="me-2" />
                <div>
                  Drag and drop photo{isMulti ? "s" : ""} here or{" "}
                  <span onClick={() => hiddenFileInputPhoto.current.click()}>
                    click to upload
                  </span>
                </div>
              </div>
            </div>
            <div>
              {previewImages.map((image, index) => (
                <ImageContainer
                  key={index}
                  alt={`${index}`}
                  src={image}
                  className="border m-1 w-100 h-auto border-radius-default"
                />
              ))}
            </div>
          </Form>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose} className="text-dark">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleUploadClick}
          type="submit"
          disabled={!images.length}
        >
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadPhotosModal;
