import { HttpMethod } from "./Api";
import { useApiMutation } from "./Api.hooks";

const endpoint = `/api/auth/2fa`;

export const useDisable2FA = () => {
  return useApiMutation(HttpMethod.POST, `${endpoint}/disable`);
};

export const useSetup2FA = () => {
  return useApiMutation(HttpMethod.POST, `${endpoint}/setup`);
};

export const useVerify2FA = () => {
  return useApiMutation(HttpMethod.POST, `${endpoint}/verify`);
};
